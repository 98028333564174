import React from 'react'

const BtnCianIcon = (props) => {

    const styleDisabled = {
        pointerEvent : 'none',
        opacity: '0.9',
        filter: 'brightness(0.5)',
        cursor: 'not-allowed'
    }

    const styleEnabled = {
        '&:hover':{
            background: 'bg-cianN/70'
        }
    }

  return (
    <button 
        className='border-solid border-cianN bg-cianN font-[Poppins] text-black border-2  font-bold 
        w-[315px] h-[44px] text-[15px] rounded-[10px] grid grid-cols-[1fr_35px] items-center justify-end
        sm:w-[315px] sm:h-[50px] sm:rounded-[10px] sm:grid-cols-[1fr_35px]' 
        style={props.active === 'true' ? styleEnabled:styleDisabled}>
                <div className=' text-center pl-[5%]'>
                    Vamos por tu nombre
                </div>
        <img className='w-[20px] h-[12px] sm:w-[22px] sm:h-[14px]' src='/icons/arrow-right-black.svg' alt='icon'/>
    </button>
  )
}

export default BtnCianIcon