import React from 'react'
import { Link } from 'react-router-dom'
import { FormTypeInput } from '../../components/form/FormTypeInput'
import ArrowBackPage from '../../components/navigation/ArrowBackPage'
import { checkPreviusValue } from '../../functions/checkPreviusValue'
import { motion } from "framer-motion"

const Form9 = () => {

  const db_data = require("../../db/form/DataForm.json")
  const data1 = db_data.flow1.page9
  const data12 = db_data.flow12.page9
  const data13 = db_data.flow13.page9
  const data3 = db_data.flow3.page9
  const data4 = db_data.flow4.page9

  var dataStorage = JSON.parse(localStorage.getItem('data'))
  const { LastValueSave, lastItem } = checkPreviusValue(9)

  return (
    <motion.div
      transition={{ duration: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='max-w-[1016px] mx-auto h-full pb-[5%] relative'>
      <ArrowBackPage beforeLink="/creacion-de-nombre-professional/08" />

      {(() => {
        switch (dataStorage.data[1].subtitle) {
          case "¿Este nombre sera para un producto , servicio o negocio?":
            if (dataStorage.data[1].value === "Producto") {
              return <FormTypeInput {...data1} LastValueSave={LastValueSave} />
            } else if (dataStorage.data[1].value === "Servicio") {
              return <FormTypeInput {...data12} LastValueSave={LastValueSave} />
            } else {
              return <FormTypeInput {...data13} LastValueSave={LastValueSave} />
            }

          case "Cuéntanos, ¿Cuándo es el nacimiento de tu hij@?":
            return <FormTypeInput {...data3} LastValueSave={LastValueSave} />

          case "¿Es un evento o celebración?":
            if (dataStorage.data[1].value === "Evento (Congreso, concierto, fiesta...") {
              return <FormTypeInput {...data3} LastValueSave={LastValueSave} />
            } else if (dataStorage.data[1].value === "Celebración (Cumpleaños, primera comunión...") {
              return <FormTypeInput {...data4} LastValueSave={LastValueSave} />
            }

          default:
            return null
        }
      })()
      }
    </motion.div>
  )
}


export default Form9