import Main from "./Main";
import { Form } from "./pages/Form";
import Home from "./pages/Home";
import { IdiomaProvider } from './context/IdiomaContext';
import Form1 from "./pages/forms/Form1"
import Form2 from "./pages/forms/Form2"
import Form3 from "./pages/forms/Form3"
import Form4 from "./pages/forms/Form4"
import Form5 from "./pages/forms/Form5"
import Form6 from "./pages/forms/Form6"
import Form7 from "./pages/forms/Form7"
import Form8 from "./pages/forms/Form8"
import Form9 from "./pages/forms/Form9"
import Form10 from "./pages/forms/Form10"
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Onboarding from "./pages/Onboarding";
import { Payment } from "./pages/Payment";
import DataForm from "./pages/DataForm";
import { Congratulations } from "./pages/Congratulations";
import { Error } from "./pages/Error";
import ScrollToTop from "./hooks/ScrollToTop";
import NamePeople from "./pages/mainPage/NamePeople";
import NameBusiness from "./pages/mainPage/NameBusiness";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  const [splash, setSplah] = useState(true)

  useEffect(() => {
    setTimeout(() => setSplah(!splash), 4000)
  }, [])

  return (

    <AnimatePresence>
      {splash ?
        <div className="w-[100vw] h-[100vh] bg-white flex items-center justify-center">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2, delay: 1 }}
            src="/logos-naming/logo-principal.svg"
            alt=""
            className="scale-[1.5]"
          />
        </div>
        :
        <div className='App w-full mx-auto overflow-x-hidden'>
          <BrowserRouter >
            <IdiomaProvider>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Main />}>
                  <Route index element={<Home />} />
                  <Route path="nombres-para-personas-y-mascotas" element={<NamePeople />} />
                  <Route path="nombres-para-marcas-y-negocios" element={<NameBusiness />} />
                  <Route
                    path={"creacion-de-nombre-professional"}
                    element={<Form />}>
                    <Route index path="01" element={<Form1 />} />
                    <Route path="02" element={<Form2 />} />
                    <Route path="03" element={<Form3 />} />
                    <Route path="04" element={<Form4 />} />
                    <Route path="05" element={<Form5 />} />
                    <Route path="06" element={<Form6 />} />
                    <Route path="07" element={<Form7 />} />
                    <Route path="08" element={<Form8 />} />
                    <Route path="09" element={<Form9 />} />
                    <Route path="10" element={<Form10 />} />
                  </Route>
                  <Route path="onboarding" element={<Onboarding />} />
                  <Route path="onboarding" element={<Onboarding />} />
                  <Route path="nombre-rapido" element={<DataForm />} />
                  <Route path="Payment" element={<Payment />} />
                  <Route path="Congratulations" element={<Congratulations />} />
                  <Route path="Error" element={<Error />} />
                </Route>
              </Routes>
            </IdiomaProvider>
          </BrowserRouter>
          <FloatingWhatsApp
            phoneNumber="+51933509472"
            accountName="Ciclos Studio"
            statusMessage="Naming Design"
            chatboxClassName="Hola"
            avatar="/iconWsp.jpg"
            className="Hola"
            placeholder="Escribe tu mensaje..."
            chatMessage="¡Hola! ¿Podemos ayudarte en algo?"
            allowEsc
            allowClickAway
            notification
            notificationSound
          />
        </div>
      }
    </AnimatePresence>
  )
}

export default App;
