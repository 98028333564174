import React from 'react'

const HeroChange = (props) => {
    return (
        <div
            className='flex mx-auto sm:w-full  h-full
            justify-center items-start z-10
            lg:items-center lg:justify-end'>
            <img
                src={props.img}
                alt="Imagen Hero"
                className="w-[50%] lg:w-[75%]"
            />
        </div>
    )
}

export default HeroChange