import React from 'react'
import { motion } from 'framer-motion'
const CardTabs = (props) => {
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='bg-white border-[1px] border-solid border-[#F1F1F1] shadow-[5px_5px_6px_rgba(184,184,184,0.25)]
      w-[343px] mx-auto sm:pb-[16px] h-[80px] sm:h-[249px]
      sm:w-[354px]'>
      <div className='flex sm:grid items-center gap-x-[24px] sm:gap-0'>
        <img alt='img' src={props.img}
          className=' w-[146px] h-[80px] sm:w-full sm:h-[184px]' />
        <div className='flex flex-col sm:items-center'>
          <h4 className='font-[Mulish] font-bold text-[12px] leading-[20px] text-[#FF0062] sm:mt-[11px]'>
            {props.subtitle}
          </h4>
          <h3 className='font-[Poppins] font-bold  text-[14px] leading-[18px] transition-all'>
            {props.title}
          </h3>
        </div>
      </div>
    </motion.div>
  )
}

export default CardTabs
