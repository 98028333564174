export const saveData = (value, subtitle, currentLink, textSaved) => {
    var dataStorage = JSON.parse(localStorage.getItem('data'))

    let dataToSave = {
        subtitle,
        value,
        currentLink
    }
    if (dataStorage) {
        dataStorage.data.push(dataToSave)
    } else {
        dataStorage = new Object()
        dataStorage["data"] = []
        dataStorage.data.push(dataToSave)
    }

    localStorage.setItem('data', JSON.stringify(dataStorage))
}