import React from 'react'
import { FormTypeInput } from '../../components/form/FormTypeInput'
import { FormFlujo1RB } from '../../components/form/FormFlujo1RB';
import { FormFlujo4RB } from '../../components/form/FormFlujo4RB';
import ArrowBackPage from '../../components/navigation/ArrowBackPage';
import { checkPreviusValue } from '../../functions/checkPreviusValue';
import { motion } from "framer-motion"

const Form2 = () => {
  const db_data = require("../../db/form/DataForm.json")
  const data1 = db_data.flow1.page2
  const data2 = db_data.flow2.page2
  const data3 = db_data.flow3.page2
  const data4 = db_data.flow4.page2
  
  const typeName = localStorage.getItem('option')
  localStorage.removeItem('option')

  const { LastValueSave, lastItem } = checkPreviusValue(2)

  return (
    <motion.div
      transition={{ duration: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='max-w-[1016px] mx-auto relative h-full  min-h-[100vh] pb-[5%]'>

      { !typeName && <ArrowBackPage beforeLink="/creacion-de-nombre-professional/01" />}
      {(() => {
        switch (lastItem.value) {
          case "mi idea de negocio, producto o servicio":
            return <FormFlujo1RB {...data1} LastValueSave={LastValueSave} />

          case "mi mascota o mejor amig@":
            return <FormTypeInput {...data2} LastValueSave={LastValueSave} />

          case "mi hija o hijo (Personas)":
            return <FormTypeInput {...data3} LastValueSave={LastValueSave} />

          case "un evento ó celebración":
            return <FormFlujo4RB {...data4} LastValueSave={LastValueSave} />

          default:
            return null
        }
      })()
      }
    </motion.div>
  )
}

export default Form2