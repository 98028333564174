import React from 'react'
import { FormattedMessage } from 'react-intl'


const Footer = () => {
  return (
    <div className='bg-blackN w-full mx-auto'>
      <div className=' flex justify-center items-center pt-[32px]'>

        <div className='grid tracking-[0.3px]'>
          <h2 className='text-white text-center text-[32px] font-extrabold font-[Mulish] tracking-[0.3px]  md:text-[52px]'>
            <FormattedMessage
              id='FooterPrincipal.contact'
              defaultMessage='Contáctanos' />
          </h2>
          <p className='font-[Mulish] text-white mt-[22px] text-center font-[400] text-[13px] md:text-[16px]  md:mt-[42px] '>
            <FormattedMessage
              id='FooterPrincipal.text1'
              defaultMessage='¡Conversemos sobre tu proyecto y lo que quieres lograr!' />
          </p>
          <a href='https://api.whatsapp.com/send?phone=+51933509472&text=%C2%A1Hola,%20quiero%20un%20proyecto%20de%20Naming%20a%20medida%20para%20mi%20nueva%20empresa!' target="_blank">
            <button className='mx-auto mt-[19px] md:mt-[29px] rounded-[25.6155px] text-white bg-[#FF0062] h-[40px] 
              font-[Poppins] font-bold text-[14px] flex flex-row items-center justify-center w-[150.11px] sm:w-[242.11px]'>
              <img className='mr-[6px]' src='./icons/btn-contact-white.svg' alt='icon' />
              Escribenos
            </button>
          </a>
          <p className=' mt-[18px] md:mt-[28px] font-[Mulish] text-center font-[400] text-[13px] md:text-[16px]  text-white'>
            <FormattedMessage
              id='FooterPrincipal.text2'
              defaultMessage='ó si gustas, escríbenos y coordinemos' />
          </p>
          <p className='mt-[19px] font-[Poppins] font-bold text-[15px] md:text-[18px] text-center text-[#FFF492] leading-[22px] '>
            hi@naming.design
          </p>
        </div>
      </div>

      <div className='w-full pt-[32px] pb-[24px]'>
        <div className='lg:h-[80px] max-w-[1440px] mx-auto  '>
          <div className='mx-[12.5%]  flex flex-col h-[100%] items-center justify-center 
          lg:flex-row lg:items-center lg:gap-[]'>
            <div className=' flex justify-center mt-2 w-full space-x-1 
            lg:mt-0 lg:items-center xl:justify-end lg:order-3'>
              <a href='https://www.linkedin.com/company/naming-design' target="_blank"><img className='icon-en' src='../icons/linkedin.svg' alt='icon' /></a>
              <a href='https://www.facebook.com/naming.design' target="_blank"><img className='icon-en' src='../icons/facebook.svg' alt='icon' /></a>
              <a href='https://www.instagram.com/naming.design' target="_blank"><img className='icon-en' src='../icons/instagram.svg' alt='icon' /></a>
            </div>
            <div className='mt-7 text-center justify-center w-full  
            md:mt-0  xl:flex  xl:justify-start xl:mt-0'>
              <p className="text-white text-[10px] mt-1 md:text-[14px]">
                Todos los derechos reservados ©
                <a 
                  target="_blank"
                  className='hover:text-blue-400'
                  href='https://www.ciclos.studio/'>
                  Ciclos Studio 2023
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer