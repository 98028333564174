import React, { useState } from 'react'
import BtnBlackContinue from '../../components/buttons/BtnBlackContinue'
import { Link } from 'react-router-dom'
import { saveData } from '../../functions/saveData'

export const FormTypeInput = (props) => {
 
  const [text2, setText2] = useState(props.LastValueSave ? props.LastValueSave.value : '')

  function handleChangeEvent(e) {
    setText2(e.target.value)
  }
  return (
    <div className='lg:w-[1016px] mx-auto px-[5%]'>
      <h1
        className='font-nunito font-[600] text-black text-center mx-auto 
         text-[18px] leading-[25px] w-[226px]
         sm:text-[36px] sm:leading-[44px] sm:w-[511px]
         lg:text-[36px] lg:leading-[44px] lg:w-[511px]'>
        {props.title}
      </h1>
      <div
        className='pt-[24px] relative grid flex-row-reverse pb-[50px]
         gap-[11px] justify-center sm:pt-[44px] sm:gap-[50px]
         xl:grid-cols-[592px_322px] xl:gap-[102px] xl:justify-start'>
        <img
          alt='imagen'
          className='mx-auto h-[138px] sm:h-[322px] xl:order-2 xl:mx-0'
          src={props.img}
        />
        <div className='grid'>
          <h2 className='font-[Mulish] font-bold text-blackN
           text-[17px] leading-[25px] text-center
           sm:text-[20px] sm:leading-[28px]
           lg:text-[24px] lg:leading-[30px] xl:!text-left lg:px-0'>
            {props.subtitle}
          </h2>
          <textarea
            name="picked"
            col='60'
            value={text2}
            onChange={handleChangeEvent}
            className='bg-[#F8F8F8] border-[1px] border-solid overflow-hidden rounded-t-[5px] border-[#7D7D7D]/60 
            w-full h-[115px] my-[28px]  mx-auto pb-[30%]
            sm:w-[592px] sm:h-[216px] sm:my-[28px] sm:px-[10px] sm:pb-[30%]  '
            placeholder={props.placeHolder}>
          </textarea>
          {
            text2 ?
              <Link
                to={props.nextLinkNavigation}
                onClick={() =>
                  saveData(text2, props.subtitle, `Form${props.localPage}`, props.LastValueSave)
                }
                className='relative w-full flex justify-center xl:!justify-start '
              >
                <BtnBlackContinue active={"true"} />
              </Link>
              :
              <div
                className='relative w-full justify-center flex xl:!justify-start '
              >
                <BtnBlackContinue active={"false"} />
              </div>
          }
        </div>
      </div>
    </div>
  )
}
