import React from 'react'
import { FormTypeInput } from '../../components/form/FormTypeInput'
import ArrowBackPage from '../../components/navigation/ArrowBackPage';
import { checkPreviusValue } from '../../functions/checkPreviusValue';
import { motion } from "framer-motion"

const Form10 = () => {

  const db_data = require("../../db/form/DataForm.json")
  const data13 = db_data.flow13.page10

  var dataStorage = JSON.parse(localStorage.getItem('data'))
  const { LastValueSave, lastItem } = checkPreviusValue(10)

  return (
    <motion.div
      transition={{ duration: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='max-w-[1016px] mx-auto h-full pb-[5%] relative'>
      <ArrowBackPage beforeLink="/creacion-de-nombre-professional/09" />

      {(() => {
        switch (dataStorage.data[1].value) {
          case "Negocio":
            return <FormTypeInput {...data13} LastValueSave={LastValueSave} />
          default:
            return null
        }
      })()
      }
    </motion.div>
  )
}

export default Form10