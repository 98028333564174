import React from "react";
import { render } from 'react-dom';
import App from "./App";
import './index.css'
import 'flowbite';


const container = document.getElementById('root');
render(<App tab="home" />, container);

