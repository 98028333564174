import React from 'react'

const BtnWhiteContinue  = (props) => {


    const styleEnabled = {
        '&:hover':{
            background: 'bg-blackN/80'
        }
    }

  return (
    <div 
        className='border-solid border-white bg-white font-[Poppins] text-black border-2  font-bold 
        w-[315px] h-[44px] text-[19px] rounded-[10px] grid grid-cols-[1fr_35px] items-center justify-end
        sm:w-[315px] sm:h-[50px] sm:rounded-[10px] sm:grid-cols-[1fr_35px]' 
        style={styleEnabled}>
                <div className=' text-center pl-[5%]'>
                    Continuar
                </div>
        <img className='w-[20px] h-[12px] sm:w-[22px] sm:h-[14px]' src='../icons/arrow-right-black.svg' alt='icon'/>
    </div>
  )
}

export default BtnWhiteContinue