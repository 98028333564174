import React from 'react'

const CardWithoutBg = (props) => {
    return (
        <div
            className='mx-auto
             w-[353px] transition-all delay-150 pb-[18px]
             sm:scale-[1.05] md:scale-90 lg:scale-[0.8] xl:scale-100'>
            <img
                className='mx-auto my-[16px] w-[156.38px] h-[144.87px] transition-all delay-150
                 sm:my-[26px] sm:w-[207px] sm:h-[200px]'
                src={props.img}
                alt="card" 
            />
            <h3
                style={{color: props.color}}
                className='text-center font-[Poppins] font-extrabold mt-[25px] text-[24px]'>
                {props.title}
            </h3>
            <p
                className='text-center mx-auto font-[Mulish] font-[400] h-[61px] w-[315px] text-[15px] 
                 mt-[12px] sm:mt-[19px] transition-all delay-150 text-white'>
                {props.text}
            </p>
        </div>
    )
}

export default CardWithoutBg