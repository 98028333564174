import React, { useState, useContext } from 'react'
import { IdiomaContext } from '../context/IdiomaContext'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { AnimatePresence, motion } from 'framer-motion'

const NavbarMain = () => {
  //Page location
  const location = useLocation()
  const urlActual = location.pathname
  const md = useMediaQuery({
    query: '(min-width: 780px)'
  })

  const [navActive, setNavActive] = useState(false)

  const linkES = (urlActual === '/Payment' || urlActual === '/nombre-rapido' || urlActual === '/nombre-rapido/negocio') ? '/icons/idioma-ES-Yellow.svg' : '/icons/idioma-ES.svg'
  const linkEN = (urlActual === '/Payment' || urlActual === '/nombre-rapido' || urlActual === '/nombre-rapido/negocio') ? '/icons/idioma-EN-Yellow.svg' : '/icons/idioma-EN.svg'

  const Lenguaje = useContext(IdiomaContext)
  const [idioma, setIdioma] = useState(linkES)

  /*
    function handleClick() {
      if (idioma === linkES) {
        setIdioma(linkEN)
        Lenguaje.establecerLenguaje('es-US')
      } else {
        setIdioma(linkES)
        Lenguaje.establecerLenguaje('es-PE')
      }
    }
  */

  return (
    <AnimatePresence>
      <header
        style={urlActual === '/Payment' || urlActual === '/nombre-rapido' || urlActual === '/nombre-rapido/negocio' ?
          { background: '#2B2326' } : { background: 'white' }
        }
        className="!z-50 drop-shadow-[0px_4px_10px_rgba(0,0,0,0.15)]
         w-full mx-auto h-[60px] md:h-[60px] xl:h-[68px] relative
         px-[8%] md:px-[0%] xl:px-[8%] desktop:px-0 ">
        <div
          className=" h-[100%] flex items-center max-w-[1200px] mx-auto
           md:scale-75 lg:scale-90 xl:scale-100 ">
          <div className='flex justify-start'>
            <Link to='/' className=''>
              <img
                className="w-full h-[46px] sm:h-[51.93px]"
                alt="logo"
                src={urlActual === '/Payment' || urlActual === '/nombre-rapido' || urlActual === '/nombre-rapido/negocio' ?
                  "/logos-naming/logo-principal-white.svg"
                  : "/logos-naming/logo-principal.svg"}
              />
            </Link>
          </div>
          {md ?
            <>
              <ul
                className="my-auto flex items-center font-[Mulish] font-[800] w-full justify-end
                 md:gap-[24px] lg:gap-[40px] xl:gap-x-[63px] ">
                <Link
                  onClick={() => { setNavActive(!navActive) }}
                  to="/"
                  style={{ color: urlActual === '/nombre-rapido' || urlActual === '/nombre-rapido/negocio' ? "white" : "#2B2326" }}
                  className="hover:text-magentaN cursor-pointer">
                  Inicio
                </Link>
                <Link
                  onClick={() => { setNavActive(!navActive) }}
                  to="/nombres-para-personas-y-mascotas"
                  style={{ color: urlActual === '/nombre-rapido' || urlActual === '/nombre-rapido/negocio' ? "white" : "#2B2326" }}
                  className="hover:text-magentaN cursor-pointer">
                  Personas y mascotas
                </Link>
                <Link
                  onClick={() => { setNavActive(!navActive) }}
                  to="/nombres-para-marcas-y-negocios">
                  <button
                    className="bg-yellowN h-[40px] font-[Mulish] px-[35px]  
                   rounded-full text-[14px] font-[800] hover:text-blackN hover:bg-yellowN/80">
                    Márcas y negocios
                  </button>
                </Link>
              </ul>
              <button
                className='ml-[25px]'>
                <img className="h-[30px] sm:h-[27px]" alt="Idioma" src={idioma} />
              </button>
            </>
            :
            <div
              onClick={() => setNavActive(!navActive)}
              className='flex justify-end w-full cursor-pointer'>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-menu-2"
                width="32"
                height="32" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 6l16 0"></path>
                <path d="M4 12l16 0"></path>
                <path d="M4 18l16 0"></path>
              </svg>
            </div>
          }
        </div>
        {navActive && !md &&
          <motion.div
            transition={{ duration: 1 }}
            initial={{ opacity: 0.5, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0 }}
            className='w-[100vw] absolute h-[calc(100vh-58px)]  px-4
             left-0 bg-black flex items-center justify-center'>
            <ul
              className='text-white text-[Poppins] grid gap-2  w-full text-center text-[18px]
               font-bold mx-4 translate-y-[-64px] items-center  text-[18px]'>
              <Link
                onClick={() => { setNavActive(!navActive) }}
                to="/"
                className="h-full cursor-pointer rounded-t-lg  py-3 px-4 border-b-[2.5px] rounded-b-lg
               hover:bg-white hover:text-black  text-[18px] ">
                Inicio
              </Link>
              <Link
                onClick={() => { setNavActive(!navActive) }}
                to="/nombres-para-personas-y-mascotas"
                className="h-full cursor-pointer py-3 px-4 border-b-[2.5px] rounded-b-lg rounded-t-lg 
               hover:bg-white hover:text-black mt-1  text-[18px] ">
                Personas y mascotas
              </Link>
              <Link
                onClick={() => { setNavActive(!navActive) }}
                to="/nombres-para-marcas-y-negocios"
                className="h-full cursor-pointer py-3 px-4 border-b-[2.5px] mt-1  text-[18px]
               hover:bg-white hover:text-black rounded-b-lg  rounded-t-lg ">
                Márcas y negocios
              </Link>
            </ul>
          </motion.div>
        }
      </header>
    </AnimatePresence>
  )
}

export default NavbarMain