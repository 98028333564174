import React from 'react'

const BtnBlackContinue = (props) => {
    const styleDisabled = {
        pointerEvent: 'none',
        opacity: '0.5',
        cursor: 'not-allowed',
        background: "gray",
        border: "none"
    }

    const styleEnabled = {
        '&:hover': {
            background: 'bg-blackN/80'
        }
    }

    return (
        <button
            className='border-solid border-blackN bg-blackN font-[Poppins] text-white border-2  font-bold 
             w-[315px] h-[44px] text-[19px] rounded-[10px] grid grid-cols-[1fr_35px] items-center justify-end
             sm:w-[315px] sm:h-[50px] sm:rounded-[10px] sm:grid-cols-[1fr_35px]'
            style={props.active === "true" ? styleEnabled : styleDisabled}>
            <div className=' text-center pl-[5%]'>
                Continuar
            </div>
            <img
                className='w-[20px] h-[12px] sm:w-[22px] sm:h-[14px]'
                src='../icons/arrow-right-white.svg'
                alt='icon'
            />
        </button>
    )
}

export default BtnBlackContinue