import React, { useState } from 'react'
import BtnCianIcon from '../components/buttons/BtnCianIcon'
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

const DataForm = () => {

  var nombreGuardado = localStorage.getItem('nombre')
  var emailGuardado = localStorage.getItem('email')

  const [correo, setCorreo] = useState(
    emailGuardado == null ? '' : emailGuardado
  )

  const [nombre, setNombre] = useState(
    nombreGuardado == null ? '' : nombreGuardado
  )

  function handleChangeEventName(e) {
    setNombre(e.target.value)
    localStorage.setItem('nombre', e.target.value)
  }

  function handleChangeEventEmail(e) {
    setCorreo(e.target.value)
    localStorage.setItem('email', e.target.value)
  }

  return (
    <motion.div
      transition={{ duration: 1.5 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='bg-blackN min-h-[100vh] pt-[30px] pb-[100px]  
       sm:pt-[62px] sm:pb-[132px]'>
      <h1
        className='font-[Poppins] font-extrabold mx-auto text-white text-center
        text-[16px] leading-[20px] w-[199px]
        sm:text-[36px] sm:leading-[44px] sm:w-[557px]'>
        Tu nombre en menos de lo que canta un Gallo!
      </h1>
      <div
        className='flex flex-col-reverse justify-center items-center
        lg:grid lg:grid-cols-[558px_322px] lg:pt-[64px]'>
        <div
          className=' flex flex-col w-[316px] font-[Mulish] font-bold text-[#15EBEB]  text-[18px] leading-[25px] tracking-[0.3px]
           mt-[32px]
           sm:w-[400px]  sm:mt-[16px]
           md:font-[400] lg:text-white lg:w-full '>
          <h2
            className=' w-[232px] text-cianN  text-[18px]
             sm:text-center sm:mx-auto sm:text-[24px] sm:w-[270px]
             lg:text-left lg:m-0 lg:w-full'>
            ¡Antes de empezar, necesitamos tus datos!
          </h2>
          <div className='rounded-t-[5.33px] mt-[32px] h-[40px] '>
            <input
              type='text'
              name="names"
              onChange={handleChangeEventName}
              value={nombre}
              className='rounded-t-[5.33px] pl-[16px] py-[10px] text-black  
                    w-[316px] text-[14px]
                    sm:w-full sm:h-[60px] sm:text-[18px]
                    lg:w-[400px] 
                    focus:outline-gray-300 focus:shadow-none'
              placeholder='Nombre'>
            </input>
          </div>
          <div className='rounded-t-[5.33px] mt-[32px] h-[40px]'>
            <input
              type='email'
              name="email"
              onChange={handleChangeEventEmail}
              value={correo}
              className='rounded-t-[5.33px] pl-[16px] py-[10px] text-black  
                    w-[316px] text-[14px]
                    sm:w-full sm:h-[60px] sm:text-[18px]
                    lg:w-[400px] 
                    focus:outline-gray-300 focus:shadow-none'
              placeholder='Email' />
          </div>
          <nav className='mt-[31px] flex 
                 sm:justify-center sm:mt-[50px] lg:justify-start lg:mt-[64px]'>
            {correo === '' || nombre === '' ?
              <BtnCianIcon active='false' />
              :
              <Link to={"/onboarding"} >
                <BtnCianIcon active='true' />
              </Link>
            }
          </nav>
        </div>
        <img className='w-[231px] mx-auto mt-[26px]
              sm:w-[500px] sm:mt-[30px]
              lg:w-[322px] lg:h-[271px] lg:mt-[36px]'
          src='/img/Form/dataform.png' alt="hero" />
      </div>
    </motion.div>
  )
}

export default DataForm