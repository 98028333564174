import React, { useState } from 'react'
import BtnBlackContinue from '../../components/buttons/BtnBlackContinue'
import { Link, Navigate } from 'react-router-dom'
import { saveData } from '../../functions/saveData'
import { motion } from "framer-motion"

const Form1 = () => {
  const array = [
    "Quiero un nombre para:",
    "mi idea de negocio, producto o servicio",
    "mi mascota o mejor amig@",
    "mi hija o hijo (Personas)",
    "un evento ó celebración"
  ]

  localStorage.removeItem('data')


  function handleChangeEvent(e) {
    setOption(e.target.value)
  }

  const LastValueSave = null
  const [option, setOption] = useState(array[1])

  const optionNegocio = localStorage.getItem("option")
  if (optionNegocio === "negocio") {
    saveData(option, array[0], "Form1", LastValueSave)
    return <Navigate to={"../02"} replace={true} />
  }

  return (
    <motion.div
      transition={{ duration: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='max-w-[800px] mx-auto flex-none h-full pb-[56px]'>
      <h1
        className='font-nunito font-[600] text-black text-center mx-auto 
        text-[18px] leading-[25px] w-[226px]
        sm:text-[36px] sm:leading-[44px] sm:w-[511px]
        lg:text-[36px] lg:leading-[44px] lg:w-[511px]'>
        ¡Bienvenido a este espacio de creación rápida!
      </h1>
      <div className='pt-[24px] relative grid flex-row-reverse
          gap-[11px] justify-center sm:pt-[44px]
          lg:grid-cols-[350px_333px] lg:gap-[121px] lg:justify-start'>
        <img
          alt='img'
          className=' mx-auto h-[124px] sm:h-[360px] lg:order-2 lg:mx-0'
          src='/img/Form/page1.png'
        />
        <div className=''>
          <h3
            className='font-[Mulish] font-bold text-blackN 
            text-[17px] leading-[25px] text-center
            sm:text-[20px] sm:leading-[28px]
            lg:text-[24px] lg:leading-[30px] lg:!text-left'>
            {array[0]}
          </h3>
          <form className='pt-[18px] pb-[40px] md:py-[20px]'>
            <ul className=''>

              <li className='h-[40px]'>
                <input
                  type="radio"
                  id="option1"
                  onChange={handleChangeEvent}
                  checked={option === array[1] ? 'checked' : ''}
                  name="option"
                  value={array[1]}
                  style={stylesRB}
                />
                <label
                  className='text-[14px] sm:text-[16px]'
                  htmlFor="option1"
                  style={styleLabel}>
                  {array[1]}
                </label>
              </li>

              <li className='h-[40px]'>
                <input
                  type="radio"
                  id="option4"
                  onChange={handleChangeEvent}
                  checked={option === array[4] ? 'checked' : ''}
                  name="option"
                  value={array[4]}
                  style={stylesRB}
                />
                <label
                  className='text-[14px] sm:text-[16px]'
                  htmlFor="option4"
                  style={styleLabel}>
                  {array[4]}
                </label>
              </li>

              <li className='h-[40px]'>
                <input
                  type="radio"
                  id="option2"
                  onChange={handleChangeEvent}
                  checked={option === array[2] ? 'checked' : ''}
                  name="option"
                  value={array[2]}
                  style={stylesRB}
                />
                <label
                  className='text-[14px] sm:text-[16px]'
                  htmlFor="option2"
                  style={styleLabel}>
                  {array[2]}
                </label>
              </li>

              <li className='h-[40px]'>
                <input
                  type="radio"
                  id="option3"
                  onChange={handleChangeEvent}
                  checked={option === array[3] ? 'checked' : ''}
                  name="option"
                  value={array[3]}
                  style={stylesRB}
                />
                <label
                  className='text-[14px] sm:text-[16px]'
                  htmlFor="option3"
                  style={styleLabel}>
                  {array[3]}
                </label>
              </li>
            </ul>
          </form>
          <Link
            onClick={() => saveData(option, array[0], "Form1", LastValueSave)}
            className=' bottom-0 mx-auto left-0 lg:absolute'
            to="../02">
            <BtnBlackContinue active="true" />
          </Link>
        </div>
      </div>
    </motion.div>
  )
}


const stylesRB = {
  color: "#FF0062",
  height: "26.67px",
  width: "26.67px",
  ':active': {
    border: '1px solid black'
  },
}

const styleLabel = {
  fontFamily: "Mulish",
  fontWeight: 400,
  color: "#263238",
  marginLeft: "10px"
}


export default Form1
