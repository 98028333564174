import React from 'react'
import BtnBorderBlack from '../buttons/BtnBorderBlack'
import BtnMagentaIcon from '../buttons/BtnMagentaIcon'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'

const CardContact = () => {
  return (
    <motion.div
    transition={{ duration: 1.5}}
    initial={{ opacity: 0}}
    animate={{ opacity: 1}}
    exit={{ opacity: 0}}>
      <div 
        className='bg-white border-[1px] border-solid border-[#F1F1F1] shadow-[5px_5px_6px_rgba(184,184,184,0.25)]
        w-[343px] mx-auto pt-[30px] pb-[25px] h-[250px] 
        sm:w-[354px]'>
        <p 
          className='font-[Mulish] font-[400] text-center mx-[10%] leading-[20px] text-[15px] '>
          Escríbenos si necesitas un proyecto o utiliza nuestra herramienta online para nombres rápidos.
        </p>
        <div 
          className='mt-[15px] sm:mt-[22px] flex flex-col items-center'>
          <a href='https://api.whatsapp.com/send?phone=+51933509472&text=%C2%A1Hola,%20quiero%20un%20proyecto%20de%20Naming%20a%20medida%20para%20mi%20nueva%20empresa!'
            target="_blank">
            <BtnBorderBlack />
          </a>
          <Link to='nombre-rapido'
              className="mt-[12px]">
              <BtnMagentaIcon/>
          </Link>
        </div>
      </div>
    </motion.div>
  )
}

export default CardContact