import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { motion } from "framer-motion"

export const Form = () => {

  const location = useLocation()
  const urlActual = location.pathname


  var cond1 = true
  var cond2 = true
  var cond3 = true
  var cond4 = true
  var cond5 = true

  var dataStorage = JSON.parse(localStorage.getItem('data'))
  var dataStorageFlujo = dataStorage &&
    urlActual === '/creacion-de-nombre-professional/03' || urlActual === '/creacion-de-nombre-professional/04'
    || urlActual === '/creacion-de-nombre-professional/05' || urlActual === '/creacion-de-nombre-professional/06'
    || urlActual === '/creacion-de-nombre-professional/07' || urlActual === '/creacion-de-nombre-professional/08'
    || urlActual === '/creacion-de-nombre-professional/09' || urlActual === '/creacion-de-nombre-professional/10'
    ? dataStorage.data[1].value : ""
  dataStorage = dataStorage?.data[0].value
  if (dataStorage === "mi idea de negocio, producto o servicio" && dataStorageFlujo === "Producto") {
    cond1 = (urlActual === '/creacion-de-nombre-professional/03')
    cond2 = (urlActual === '/creacion-de-nombre-professional/02' || urlActual === '/creacion-de-nombre-professional/03' || urlActual === '/creacion-de-nombre-professional/04')
    cond3 = (urlActual === '/creacion-de-nombre-professional/05' || urlActual === '/creacion-de-nombre-professional/06')
    cond4 = (urlActual === '/creacion-de-nombre-professional/07' || urlActual === '/creacion-de-nombre-professional/08')
    cond5 = (urlActual === '/creacion-de-nombre-professional/09')

  } else if (dataStorage === "mi idea de negocio, producto o servicio" && dataStorageFlujo === "Servicio") {
    cond1 = (urlActual === '/creacion-de-nombre-professional/03')
    cond2 = (urlActual === '/creacion-de-nombre-professional/02' || urlActual === '/creacion-de-nombre-professional/03' || urlActual === '/creacion-de-nombre-professional/04')
    cond3 = (urlActual === '/creacion-de-nombre-professional/05' || urlActual === '/creacion-de-nombre-professional/06')
    cond4 = (urlActual === '/creacion-de-nombre-professional/07' || urlActual === '/creacion-de-nombre-professional/08')
    cond5 = (urlActual === '/creacion-de-nombre-professional/09')

  } else if (dataStorage === "mi idea de negocio, producto o servicio" && dataStorageFlujo === "Negocio") {
    cond1 = (urlActual === '/creacion-de-nombre-professional/03')
    cond2 = (urlActual === '/creacion-de-nombre-professional/02' || urlActual === '/creacion-de-nombre-professional/03' || urlActual === '/creacion-de-nombre-professional/04' || urlActual === '/creacion-de-nombre-professional/05')
    cond3 = (urlActual === '/creacion-de-nombre-professional/06' || urlActual === '/creacion-de-nombre-professional/07')
    cond4 = (urlActual === '/creacion-de-nombre-professional/08' || urlActual === '/creacion-de-nombre-professional/09')
    cond5 = (urlActual === '/creacion-de-nombre-professional/10')

  } else if (dataStorage === "un evento ó celebración" && dataStorageFlujo === "Evento (Congreso, concierto, fiesta...") {
    cond1 = (urlActual === '/creacion-de-nombre-professional/03')
    cond2 = (urlActual === '/creacion-de-nombre-professional/02' || urlActual === '/creacion-de-nombre-professional/03')
    cond3 = (urlActual === '/creacion-de-nombre-professional/04' || urlActual === '/creacion-de-nombre-professional/05')
    cond4 = (urlActual === '/creacion-de-nombre-professional/06' || urlActual === '/creacion-de-nombre-professional/07')
    cond5 = (urlActual === '/creacion-de-nombre-professional/08' || urlActual === '/creacion-de-nombre-professional/09')

  } else if (dataStorage === "un evento ó celebración" && dataStorageFlujo === "Celebración (Cumpleaños, primera comunión...") {
    cond1 = (urlActual === '/creacion-de-nombre-professional/03')
    cond2 = (urlActual === '/creacion-de-nombre-professional/02' || urlActual === '/creacion-de-nombre-professional/03' || urlActual === '/creacion-de-nombre-professional/04')
    cond3 = (urlActual === '/creacion-de-nombre-professional/05')
    cond4 = (urlActual === '/creacion-de-nombre-professional/06')
    cond5 = (urlActual === '/creacion-de-nombre-professional/07')

  } else if (dataStorage === "mi mascota o mejor amig@") {
    cond1 = (urlActual === '/creacion-de-nombre-professional/03')
    cond2 = (urlActual === '/creacion-de-nombre-professional/02' || urlActual === '/creacion-de-nombre-professional/03')
    cond3 = (urlActual === '/creacion-de-nombre-professional/04')
    cond4 = (urlActual === '/creacion-de-nombre-professional/05')
    cond5 = (urlActual === '/creacion-de-nombre-professional/06')

  } else if (dataStorage === "mi hija o hijo (Personas)") {
    cond1 = (urlActual === '/creacion-de-nombre-professional' || urlActual === '/creacion-de-nombre-professional/02')
    cond2 = (urlActual === '/creacion-de-nombre-professional/03' || urlActual === '/creacion-de-nombre-professional/04')
    cond3 = (urlActual === '/creacion-de-nombre-professional/05' || urlActual === '/creacion-de-nombre-professional/06')
    cond4 = (urlActual === '/creacion-de-nombre-professional/07' || urlActual === '/creacion-de-nombre-professional/08')
    cond5 = (urlActual === '/creacion-de-nombre-professional/09')

  } else {
    cond1 = true
    cond2 = false
    cond3 = false
    cond4 = false
    cond5 = false
  }


  return (
    <motion.div 
      transition={{ duration: 1.5 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='w-full min-h-screen flex justify-center'>
      <div className="w-full flex flex-col ">
        <div className='mx-[4.5%] mt-[24px]
         md:mt-[40px] md:mx-[12.5vw]'>
          <ul className="grid max-w-[1016px] mx-auto space-x-2 grid-cols-[1fr_1fr_1fr_1fr_1fr]">
            <li
              className={`${(cond1 || cond2 || cond3 || cond4 || cond5) ? 'bg-[#15EBEB]' : 'bg-[#E2E2E2]'} h-[10px]  rounded-full`}>
            </li>
            <li
              className={`${(cond2 || cond3 || cond4 || cond5) ? 'bg-[#15EBEB]' : 'bg-[#E2E2E2]'} h-[10px]  rounded-full`}>
            </li>
            <li
              className={`${(cond3 || cond4 || cond5) ? 'bg-[#15EBEB]' : 'bg-[#E2E2E2]'} h-[10px]  rounded-full`}>
            </li>
            <li
              className={`${(cond4 || cond5) ? 'bg-[#15EBEB]' : 'bg-[#E2E2E2]'} h-[10px]  rounded-full`}>
            </li>
            <li
              className={`${(cond5) ? 'bg-[#15EBEB]' : 'bg-[#E2E2E2]'} h-[10px]  rounded-full`}>
            </li>
          </ul>
        </div>
        <div className='pt-[6%] md:pt-[2%] lg:pt-[2%]'>
          <Outlet />
        </div>
      </div>
    </motion.div>
  )
}

