import React from 'react'

const CardsBorderTopColor = (props) => {
    return (
        <div
            className='bg-white mx-auto  shadow-[5px_5px_6px_rgba(184,184,184,0.25)] 
            w-full  transition-all delay-150 pb-[18px]
            sm:scale-[1.05] sm:w-[353px] 
            md:scale-90 lg:scale-[0.8] xl:scale-100'>
            <div 
                className='h-[8px] w-full transition-all delay-150'
                style={{ background: props.color }}>
            </div>
            <img className='mx-auto my-[28px] h-[144.87px] transition-all delay-150
             sm:my-[26px] sm:h-[200px]' src={props.img} alt="card" />
            <h3 className='text-center font-[Poppins] font-extrabold mt-[25px] text-[24px]'>
                {props.title}
            </h3>
            <p className='text-center mx-auto font-[Mulish] font-[400] h-[61px] w-[315px] text-[15px] 
             mt-[32px] sm:mt-[19px] transition-all delay-150'>
                {props.text}
            </p>
        </div>
    )
}

export default CardsBorderTopColor