import React from 'react'
import CardTestimonialHome from '../cards/CardTestimonialHome'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

const Carousel = () => {
    return (
        <div className='text-white text-center'>
            <Swiper
                style={{
                    "--swiper-pagination-color": "#FFDC4D",
                    "--swiper-navigation-color": "#FFDC4D",
                    "--swiper-pagination-bullet-inactive-color": "#B1B1B1",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "16px",
                    "--swiper-pagination-bullet-horizontal-gap": "6px",
                    "--swiper-pagination-padding-top": "6%"
                }}
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper pb-5 translate-y-[-30px] 
                sm:translate-y-[-30px] md:translate-y-[0px]"
            >
                <SwiperSlide>
                    <div className='max-w-[707px] sm:pb-[30px] mx-auto grid gap-[16px] sm:gap-[50px]'>
                        <div className='h-[60px] sm:h-[20px] '>

                        </div>
                        <p className='text-[18px] sm:text-[24px] font-[Mulish] font-[400] text-[#FFF492] px-[15%] lg:px-0'>
                            “No entendía la importancia de un buen nombre, realmente me han ayudado mucho, siempre los recomiendo.”
                        </p>
                        <cite className=''>Rodrigo R.</cite>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='max-w-[707px] sm:pb-[30px] mx-auto grid gap-[16px] sm:gap-[50px]'>
                        <div className='h-[60px] sm:h-[20px] '>

                        </div>
                        <p className='text-[18px] sm:text-[24px] font-[Mulish] font-[400] text-[#FFF492] px-[15%] lg:px-0'>
                            “Me ayudaron un montón con el nombre de mi nuevo servicio, son geniales. Fue un buen trabajo.”
                        </p>
                        <cite className=''>Carla M.</cite>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='max-w-[707px] sm:pb-[30px] mx-auto grid gap-[16px] sm:gap-[50px]'>
                        <div className='h-[60px] sm:h-[20px] '>

                        </div>
                        <p className='text-[18px] sm:text-[24px] font-[Mulish] font-[400] text-[#FFF492] px-[15%] lg:px-0'>
                            “El Odiseus es más que mi hijo, darle un nombre que merece era importante para mí.”
                        </p>
                        <cite className=''>Peter B.</cite>
                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    )
}

export default Carousel