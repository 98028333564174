import React from 'react'
import {FormattedMessage } from 'react-intl'

const BtnBorderBlack = () => {
  return (
    <button className='border-solid border-blackN bg-white font-[Poppins] text-blackN border-2  font-bold 
    w-[315px] h-[44px]  rounded-[10px]  text-[14px] 
    sm:w-[315px] sm:h-[50px]  sm:rounded-[10px] 
    hover:text-white hover:!bg-blackN transition-all duration-500'>
        <div className=''>
            <FormattedMessage
                id='PP.btn.gray'
                defaultMessage='Quiero un nombre a mi medida'/>
        </div>
    </button>
  )
}

export default BtnBorderBlack