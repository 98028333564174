import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import BtnBorderBlackIcon from '../../components/buttons/BtnBorderBlackIcon'
import BtnBorderMagenta from '../../components/buttons/BtnBorderMagenta'
import CardPlanes from '../../components/cards/CardPlanes'
import CardsBorderTopColor from '../../components/cards/CardsBorderTopColor'
import CardWithoutBg from '../../components/cards/CardWithoutBg'
import BtnCollaps from '../../components/collaps/BtnCollaps'
import HeroChange from '../../components/imgHidden/HeroChange'
import { motion } from "framer-motion"

const NameBusiness = () => {

  const lg = useMediaQuery({ query: '(min-width: 1024px)' })
  const md = useMediaQuery({ query: '(min-width: 780px)' })

  const saveStateBuss = () => {
    localStorage.setItem('option', 'negocio')
  }

  return (
    <motion.div
      transition={{ duration: 1.5 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <main id='hero'>
        <div
          className=' flex flex-col justify-center text-center w-full 
          gap-[18px]  sm:gap-[32px]
          lg:text-left lg:w-[500px]'>
          <h2 className='text-center lg:!text-start'>
            ¿Qué es <span className='text-magentaN'>Naming</span> Design?
          </h2>
          <p
            className='text-[16px] sm:text-[20px] lg:text-[18px] lg:!text-start'>
            Somos una plataforma de diseño de nombres profesionales para marcas, emprendimientos y nuevas líneas de negocio.
            <br /><br />
            Utilizamos una metodología ágil y te entregamos una serie de propuestas que podrás utilizar.
          </p>
          {!lg && <HeroChange img="/img/buss/hero-page-bussines.png" />}
          <Link
            to={"/nombre-rapido"}
            onClick={() => saveStateBuss()}
            className='flex justify-center lg:justify-start'>
            <BtnBorderBlackIcon text="Quiero un nombre rápido" />
          </Link>
        </div>
        {lg && <HeroChange img="/img/buss/hero-page-bussines.png" />}
      </main>

      <section
        id='¿Cómo funciona?'
        className='bg-blackN text-center grid 
        gap-[32px] px-[5%]
        sm:gap-[36px] 
        md:gap-[48px]'>
        <h1 className="text-white">
          ¿Cómo funciona?
        </h1>
        <p className="text-white text-[15px] sm:text-[18px] lg:text-[16px]">
          Ingresa a{" "}
          <Link
            to={"/nombre-rapido"}
            onClick={() => saveStateBuss()}
            className='font-bold border-b-[1.5px]'>
            nuestra plataforma
          </Link>
          {" "}y sigue los siguientes pasos:
        </p>
        <div className=" grid  justify-center 
          mx-auto gap-[8px] 
          px-[5%] sm:gap-[48px]
          md:grid-cols-2 md:gap-[24px]
          lg:grid-cols-3 lg:gap-[11px] lg:px-[3%]
          xl:px-0 xl:max-w-[1117px] ">
          <CardsBorderTopColor
            title="Responde"
            text="Completa las preguntas para ayudarte a crear el nombre que necesites"
            color="#FF0062"
            img="/img/buss/onboarding-response.png"
          />
          <CardsBorderTopColor
            title="Paga"
            text="Realiza una colaboración para seguir contribuyendo a la mejora del servicio."
            color="#15EBEB"
            img="/img/buss/onboarding-pay.png"
          />
          <CardsBorderTopColor
            title="Recibe"
            text="Compartiremos las propuestas de nombre que hemos creado a través de tu email."
            color="#FFD600"
            img="/img/buss/onboarding-receives.png"
          />
        </div>
        <Link
          to="/nombre-rapido"
          onClick={() => saveStateBuss()}
          className="flex justify-center">
          <BtnBorderMagenta text="Haz tu pedido aquí" />
        </Link>
      </section>

      <section
        id='Conoce nuestros planes'
        className='sectionsTypeHero'>
        <div
          className=' flex flex-col justify-center text-center w-full 
          gap-[24px]  sm:gap-[28px]
          lg:text-left lg:w-[594px]'>
          <h2>
            Conoce nuestros planes
          </h2>

          <div
            className='grid justify-center gap-[2px] 
            sm:grid-cols-2 sm:gap-[24px]'>
            <CardPlanes
              title="Plan Regular"
              titleColor="#15EBEB"
              price="100"
              items={["Pagó único", "Entrega entre 2 a 3 días", "Metodología innovadora", "4 opciones viables para ti"]}
            />
            <CardPlanes
              title="Plan Veloz"
              titleColor="#FF0062"
              price="120"
              items={["Pagó único", "Entrega máxima en 24 horas", "Metodología innovadora", "4 opciones viables para ti"]}
            />
          </div>
          <p
            className='text-[16px] text-center sm:text-[20px] lg:!text-start lg:text-[18px]'>
            Realiza tu pedido aquí de manera rápida y sencilla.
          </p>
          <Link
            to="/nombre-rapido"
            onClick={() => saveStateBuss()}
            className='flex justify-center lg:justify-start'>
            <BtnBorderMagenta text="Haz tu pedido aquí" />
          </Link>
          {!lg && <HeroChange img="./img/buss/pay-phone.png" />}
        </div>
        {lg && <HeroChange img="./img/buss/pay-phone.png" />}
      </section>

      <section
        id='Grandes beneficios'
        className='bg-blackN text-center grid 
        gap-[32px] 
        sm:gap-[36px] 
        md:gap-[48px]'>
        <h1 className="text-white">
          Grandes beneficios
        </h1>
        <p
          className="text-white text-[15px]  max-w-[619px]
           sm:text-[18px] lg:text-[16px] mx-auto px-3">
          Un nombre bien diseñado puede lograr los objetivos planificados, por ello nuestra
          metodología prioriza las siguientes características.
        </p>
        <div className=" grid  justify-center 
          mx-auto gap-[48px] 
          sm:gap-[48px]
          md:grid-cols-2 md:gap-[24px]
          lg:grid-cols-3 lg:gap-[11px] 
          xl:px-0 xl:max-w-[1117px] ">
          <CardWithoutBg
            title="Memorable"
            text="Construye un nombre relevante y fácil de recordar para que tus clientes nunca te olviden."
            color="#FF0062"
            img="./img/buss/pronunciable.png"
          />
          <CardWithoutBg
            title="Verbalizable"
            text="Un buen nombre marca la diferencia, además si es fácil de conjugar o parafrasear, es un golazo."
            color="#15EBEB"
            img="./img/buss/memorable.png"
          />
          <CardWithoutBg
            title="Pronunciable"
            text="Nuestro equipo tiene especialistas en creatividad, innovación, diseño, literatura y construcción de marcas."
            color="#FFD600"
            img="./img/buss/verbalizable.png"
          />
        </div>
      </section>

      <section
        id='Preguntas frecuentes'
        className='bg-[#F1F4F6] text-center grid 
        gap-[32px] px-[5%]
        sm:gap-[36px] 
        md:gap-[68px]'>
        <h1 className="text-blackN">
          Preguntas frecuentes
        </h1>
        <div
          className='grid w-full mx-auto grid-cols-1 gap-[28px] items-center
          md:grid-cols-[250px_1fr] 
          lg:grid-cols-[300px_1fr] lg:gap-[54px] xl:w-[1110px]'>
          {
            md &&
            < img
              className='h-[250px] w-[250px] lg:h-[300px] lg:w-[300px]'
              alt='img'
              src='./img/home/preguntasFrecuentes.png'
            />
          }
          <div
            className='grid gap-[2px]'>
            <BtnCollaps
              id="1"
              roundedTop={true}
              roundedBot={false}
              state={true}
              question="¿Qué es Naming Design?"
              answer="Somos una plataforma de diseño de nombres profesionales para diferentes 
            tipos de usos y necesidades desde marcas, emprendimientos, nombres de personas, 
            eventos, espacios, mascotas o cualquier otro fin."
            />
            <BtnCollaps
              id="2"
              roundedTop={false}
              roundedBot={false}
              state={false}
              question="¿Cuales son los beneficios de Naming?"
              answer="Un nombre relevante y fácil de recordar para tu marca y para el mejor uso que desees"
            />
            <BtnCollaps
              id="3"
              roundedTop={false}
              roundedBot={false}
              state={false}
              question="¿Como me registro?"
              answer="No necesitas registrarte, solo realizas tu pedido, pagas y listo. 
            Te recomendamos escribir al whatsApp si tienes más consultas."
            />
            <BtnCollaps
              id="4"
              roundedTop={false}
              roundedBot={false}
              state={false}
              question="Una vez realizado el pago, ¿Cuánto se demora en crearme el Naming?"
              answer="La respuesta inicial será en 10-15 minutos posterior a realizar el pago,
            también puedes enviarnos por whatsApp o mail una captura de tu pago. Puedes elegir 
            el plan de pago inmediato, en menos de 12 horas tendremos tus propuestas terminadas, 
            el segundo es de 48 horas y el tercero de 4 o 5 días, para ello te invitamos a ver el tarifario."
            />
            <BtnCollaps
              id="5"
              roundedTop={false}
              roundedBot={true}
              state={false}
              question="¿Cuál es la diferencia entre nombre rápido y a medida?"
              answer="Nombre rápido es un proceso por el cual nos ayudamos de técnicas de investigación, 
            innovación y diseño de nombres para entregarte 3 propuestas coherentes y viables para tu necesidad. 
            El nombre a medida es una consultoría especializada que toma más tiempo, es más costosa 
            y se trabaja de la mano del cliente, si quieres conocer más, escríbenos."
            />
          </div>
        </div>
      </section>
    </motion.div>
  )
}

export default NameBusiness