import React, { useState } from 'react'

const BtnCollaps = (props) => {
    const [focus, setFocus] = useState(props.state)

    const styleTop = {
        color: !focus ? 'black' : "#FF0062",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
    }

    const styleBot = {
        color: !focus ? 'black' : "#FF0062",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
    }

    const style = {
        color: !focus ? 'black' : "#FF0062"
    }

    return (
        <>
            <p>
                <button
                    style={props.roundedTop ? styleTop : props.roundedBot ? styleBot : style}
                    className="btn bg-white w-full rounded-none py-3
                     text-start grid grid-cols-[1fr_0.1fr] font-bold"
                    onClick={() => setFocus(!focus)}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapseExample" + props.id}
                    aria-expanded="false"
                    toogle="true"
                    aria-controls="collapseExample">
                    {props.question}
                    {
                        <svg
                            style={{ transform: focus ? "rotate(180deg)" : "rotate(0deg)" }}
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-up justify-self-end"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M6 15l6 -6l6 6"></path>
                        </svg>
                    }

                </button>
            </p>
            <div
                className={props.state? "collapse show" : "collapse" }
                id={"collapseExample" + props.id}>
                <div
                    className="card card-body border-none rounded-none">
                    <p 
                        className='text-[14px] leading-[21px] text-left
                        sm:text-[15px] sm:leading-[20px]'>
                        {props.answer}
                    </p>
                </div>
            </div>
        </>
    )
}

export default BtnCollaps