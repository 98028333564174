import React, { useState } from 'react'
import BtnBlackContinue from '../../components/buttons/BtnBlackContinue'
import { Link } from 'react-router-dom'
import { usePreviusPagContext, useOptionNavContext } from '../../context/helperNavContext'


export const FormFlujo13RB = (props) => {

  const { optionNav, setOptionNav } = useOptionNavContext();
  const { previusPage, setPreviusPage } = usePreviusPagContext();

  
  let dataStorage = JSON.parse(localStorage.getItem('data'))
  let textSaved = null
  let indexArray = null


  function handleChangeEvent(e) {
    setOption(e.target.value)
  }

  const [option, setOption] = useState("1")

  function saveDate() {
    const value = props.option[parseInt(option.split('')[option.length - 1])]
    let dataToSave = {
      subtitle: props.subtitle,
      value
    }

    let object = {
      data: ""
    }

    if (textSaved) {
      //Replace value
      dataStorage.data[indexArray] = dataToSave
      object.data = dataStorage.data
    } else {
      //Add value
      object.data = dataStorage ? [...dataStorage.data, dataToSave] : [dataToSave]
    }
    localStorage.setItem('data', JSON.stringify(object))
  }


  const stylesRB = {
    color: "#FF0062",
    height: "26.67px",
    width: "26.67px",
    ':active': {
      border: '1px solid black'
    },
  }

  const styleLabel = {
    fontFamily: "Mulish",
    fontWeight: 400,
    color: "#263238",
    marginLeft: "10px"
  }

  return (
    <div className='max-w-[800px]  mx-auto flex-none h-full px-[5%] '>
      <h1 className='font-nunito font-[600] text-black text-center mx-auto 
        text-[18px] leading-[25px] w-[226px] 
        sm:text-[36px] sm:leading-[44px] sm:w-[511px]
        lg:text-[36px] lg:leading-[44px] lg:w-[511px]'>
        {props.title} a
      </h1>
      <div className='pt-[24px] relative grid flex-row-reverse
          gap-[11px] justify-center sm:pt-[44px]
          lg:grid-cols-[350px_333px] lg:gap-[121px] lg:justify-start'>
        <img className=' mx-auto h-[124px] sm:h-[360px] lg:order-2 lg:mx-0' src={props.img} />
        <div className=''>
          <h3 className='font-[Mulish] font-bold text-blackN 
                text-[17px] leading-[25px] text-center
                sm:text-[20px] sm:leading-[28px]
                lg:text-[24px] lg:leading-[30px] lg:text-left'>
            {props.subtitle}
          </h3>
          <form className='pt-[13px] pb-[15px]'>
            <ul className=''>
              <li className='h-[40px]'>
                <input type="radio" id="option1" onChange={handleChangeEvent}
                  checked={option === '1' ? 'checked' : ''}
                  name="option" value="1" style={stylesRB} />
                <label className='text-[14px] sm:text-[16px]' htmlFor="option1" style={styleLabel}>
                  {props.option[0]}
                </label>
              </li>

              <li className='h-[40px]'>
                <input type="radio" id="option2" onChange={handleChangeEvent}
                  checked={option === '12' ? 'checked' : ''}
                  name="option" value="12" style={stylesRB} />
                <label className='text-[14px] sm:text-[16px]' htmlFor="option2" style={styleLabel}>
                  {props.option[1]}
                </label>
              </li>

              <li className='h-[40px]'>
                <input type="radio" id="option3" onChange={handleChangeEvent}
                  checked={option === '13' ? 'checked' : ''}
                  name="option" value="13" style={stylesRB} />
                <label className='text-[14px] sm:text-[16px]' htmlFor="option3" style={styleLabel}>
                  {props.option[2]}
                </label>
              </li>

            </ul>
          </form>
          <Link 
            className=' bottom-0 mx-auto relative flex justify-center left-0 lg:absolute' 
            onClick={()=>saveDate()}
            to={props.nextLinkNavigation}>
            <BtnBlackContinue active="true" />
          </Link>
        </div>
      </div>
    </div>
  )
}
