import React from 'react'

const BtnBlackIconNext = () => {
  return (
    <div className='border-solid border-blackN bg-blackN font-[Poppins] text-white border-2  font-bold 
    w-[170.18px] h-[43px] text-[18px] rounded-[10px] flex justify-center items-center gap-[16px]
    sm:w-[200px] sm:h-[51px] sm:rounded-[10px] 
    hover:bg-blackN/90 hover:!border-blackN/90 hover:border'>
        <div className=' text-center'>
            Siguiente
        </div>
        <img className='w-[20px] h-[12px] sm:w-[22px] sm:h-[14px]' src='/icons/arrow-right-white.svg' alt='icon'/>
    </div>
  )
}

export default BtnBlackIconNext