import React from 'react'

const FooterSecondary = (props) => {
  return (
    <header className="h-[48px] z-50 w-full sm:h-[68px]"
      style={props.style === 'white' ? { background: 'white' } : { background: '#2B2326' }}>
      <div className='max-w-[1440px] h-full mx-auto px-[11%] flex items-center justify-center'>
        <p className='font-[Mulish] font-[500] leading-[20px] 
             text-[10px] sm:text-[14px] '
          style={props.style === 'black' ? { color: 'white' } : { color: '#2B2326' }}>
          Todos los derechos reservados ©
          <a
            target="_blank"
            className='hover:text-blue-400'
            href='https://www.ciclos.studio/'>
            Ciclos Studio 2023
          </a>
        </p>
      </div>
    </header>
  )
}

export default FooterSecondary