import React,{useState,useContext} from "react"

//This context helps us to know which is the option of the form that was selected
const OptionNavContext = React.createContext(0)
//this context helps us to know which was the page before. 
//It will help in the animation and render the component according to the selected option
const PreviusPagContext = React.createContext(0)

export function ProviderOptionNavContext ({children}) {
    const [optionNav,setOptionNav] = useState(1)
    const valor = {optionNav,setOptionNav}
    return (
        <OptionNavContext.Provider value={valor}>
            {children}
        </OptionNavContext.Provider>
    );
}

export function ProviderPreviusPagContext ({children}) {
    const [previusPage,setPreviusPage] = useState(0)
    const valor = {previusPage,setPreviusPage}
    return (
        <PreviusPagContext.Provider value={valor}>
            {children}
        </PreviusPagContext.Provider>
    );
}

export function useOptionNavContext(){
    const context = useContext(OptionNavContext)
    //In cases where the context is used in a component that is not wrapped with a ProviderOptionNavContext
    //the following error will be sent
    if(!context){
        throw new Error('useOptionNavContext must be used within a ProviderPreviusPagContext')
    }
    return context
}

export function usePreviusPagContext(){
    const context = useContext(PreviusPagContext)
    //In cases where the context is used in a component that is not wrapped with a ProviderPreviusPagContext
    //the following error will be sent
    if(!context){
        throw new Error('useOptionNavContext must be used within a ProviderPreviusPagContext')
    }
    return context
}