import React, { useState } from 'react'
import CardsTabs from './cards/CardTabs'
import CardContact from './cards/CardContact'

const Tabs = () => {

    var dbMarcas = require('../db/optionTabs/Marcas.json');
    var dbServicios = require('../db/optionTabs/Servicios.json');
    var dbMascotas = require('../db/optionTabs/Mascotas.json');
    var dbProductos = require('../db/optionTabs/Productos.json');
    var dbPersonas = require('../db/optionTabs/Personas.json');
    var dbEventos = require('../db/optionTabs/Eventos.json');
    var dbOtros = require('../db/optionTabs/Otros.json');

    var [active, setActive] = useState(0)
    var [data, setData] = useState(dbMarcas)

    const styleBtnTab = {
        fontFamily: "Mulish",
        fontWeight: 600,
        border: "1px solid #DFDFDF",
        borderRadius: "12px"
    }

    const styleActive = {
        background: "#FF0062",
        color: "white",
        filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))",
        borderRadius: "12px",
        fontWeight: 700
    }

    const styles = {
        overflowX: 'scroll',
        overflowY: '',
        "&::webkitScrollbar": {
            display: 'none'
        }
    }


    return (
        <div className='mx-auto w-full '>
            <div
                className='font-[Mulish] max-w-[1110px] mx-auto font-[600] text-[18px] text-center 
                leading-[22px] tracking-[0.3px] overflow-x-hidden'>
                <div
                    className='grid grid-cols-[120px_120px_120px_120px_120px_120px_120px] gap-[12px]  pb-1 mx-[8%] 
                     max-w-[912px] pt-2 lg:!overflow-x-hidden' style={styles}>
                    <button
                        className='transition-all h-[42px] ease-in delay-50 hover:shadow-md hover:translate-y-[-3px] '
                        style={active === 0 ? styleActive : styleBtnTab}
                        onClick={() => setActive(0) - setData(dbMarcas)}>
                        Marcas
                    </button>
                    <button
                        className='transition-all ease-in delay-50 hover:shadow-md hover:translate-y-[-3px]'
                        style={active === 1 ? styleActive : styleBtnTab}
                        onClick={() => setActive(1) - setData(dbServicios)} >
                        Servicios
                    </button>
                    <button
                        className='transition-all ease-in delay-50 hover:shadow-md hover:translate-y-[-3px]'
                        style={active === 3 ? styleActive : styleBtnTab}
                        onClick={() => setActive(3) - setData(dbProductos)} >
                        Productos
                    </button>
                    <button
                        className='transition-all ease-in delay-50 hover:shadow-md hover:translate-y-[-3px]'
                        style={active === 5 ? styleActive : styleBtnTab}
                        onClick={() => setActive(5) - setData(dbEventos)} >
                        Eventos
                    </button>
                    <button
                        className='transition-all ease-in delay-50 hover:shadow-md hover:translate-y-[-3px]'
                        style={active === 4 ? styleActive : styleBtnTab}
                        onClick={() => setActive(4) - setData(dbPersonas)} >
                        Personas
                    </button>
                    <button
                        className='transition-all ease-in delay-50 hover:shadow-md hover:translate-y-[-3px]'
                        style={active === 2 ? styleActive : styleBtnTab}
                        onClick={() => setActive(2) - setData(dbMascotas)} >
                        Mascotas
                    </button>
                    <button
                        className='transition-all ease-in delay-50 hover:shadow-md hover:translate-y-[-3px]'
                        style={active === 6 ? styleActive : styleBtnTab}
                        onClick={() => setActive(6) - setData(dbOtros)} >
                        Otros
                    </button>
                </div>
            </div>
            <div
                className='max-w-[1110px] mx-auto ' >
                <div
                    className=' mx-auto bg-slate-40 grid grid-cols-1 mt-[32px] gap-y-[16px] 
                     sm:gap-y-[40px] sm:mt-[43px]  transition-all
                     md:grid-cols-2 md:w-[800px] xl:w-auto xl:grid-cols-3'>
                    {data.map(card =>
                        <CardsTabs key={card.title} {...card} />
                    )}
                    <CardContact />
                </div>
            </div>
        </div>
    )
}

export default Tabs
