import React, {useState} from 'react'
import MensajesIngles from '../idiomas/en-US.json'
import MensajesEpañol from '../idiomas/en-PE.json'
import { IntlProvider } from 'react-intl';

const IdiomaContext = React.createContext();

const IdiomaProvider = ({children}) => {
  const [mensajes, setMensajes] = useState(MensajesEpañol);
  const [locale, setLocale] = useState('en-PE');

  const establecerLenguaje = (lenguaje) =>{
    switch(lenguaje){
      case 'es-PE': 
        setMensajes(MensajesEpañol);
        setLocale('es-PE')
        break;

        case 'es-US': 
        setMensajes(MensajesIngles);
        setLocale('es-US')
        break;
        
        default:
          setMensajes(MensajesEpañol);
          setLocale('es-PE')
    }
  }

  return (
    <IdiomaContext.Provider value={{establecerLenguaje: establecerLenguaje}}>
      <IntlProvider locale={locale} messages={mensajes}>
        {children}
      </IntlProvider>
    </IdiomaContext.Provider>
  )
}

export  {IdiomaProvider,IdiomaContext}
