import React from 'react'

const BtnBorderBlackIcon = (props) => {
    return (
        <button
            className='border-solid border-blackN bg-white font-[Poppins] 
            text-blackN border-[2.5px]  font-bold hover:text-white
            w-[315px] h-[44px] text-[14px] rounded-[10px]  transition-all ease-out
            grid grid-cols-[1fr_35px] items-center justify-end
            sm:w-[315px] sm:h-[50px] sm:rounded-[10px] sm:grid-cols-[1fr_35px]
            hover:!bg-blackN hover:shadow-md'>
            <div className=' text-center pl-[5%]'>
                {props.text}
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-arrow-narrow-right"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l14 0"></path>
                <path d="M15 16l4 -4"></path>
                <path d="M15 8l4 4"></path>
            </svg>
        </button>
    )
}

export default BtnBorderBlackIcon