import DeleteLastItemData from "./DeleteLastItemData"
import GetLastItemData from "./GetLastItemData"

export const checkPreviusValue =  (localPage) => {
    var lastItem = GetLastItemData()
    var LastValueSave = null
    var dataStorage = JSON.parse(localStorage.getItem('data'))

    if (dataStorage.data.length === localPage ) {
        LastValueSave = lastItem
        DeleteLastItemData()
        lastItem = GetLastItemData()
    }
    
    return { LastValueSave, lastItem }
}