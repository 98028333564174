import React from 'react'
import { FormattedMessage } from 'react-intl'
import { motion } from "framer-motion";


const CardBenefitsHome = (props) => {

  return (
    <motion.div
      className='bg-white mx-auto  shadow-[5px_5px_6px_rgba(184,184,184,0.25)] 
       w-[353px] transition-all delay-150'>
      <img
        className='mx-auto my-[28px] w-[156.38px] h-[144.87px] transition-all delay-150
         sm:my-[26px] sm:w-[207px] sm:h-[200px]'
        src={props.img}
        alt="card" />
      <h3
        className='text-center font-[Poppins] font-extrabold 
        mt-[25px] text-[24px]'>
        <FormattedMessage
          id={props.title}
          defaultMessage='Beneficios '
        />
      </h3>
      <p
        className='text-center mx-auto font-[Mulish] font-[400] h-[61px] w-[315px] text-[15px] 
         mt-[32px] sm:mt-[19px] transition-all delay-150'>
        <FormattedMessage
          id={props.text}
          defaultMessage='Accede a los beneficios y herramientas digitales que te ayudarán a mejorar tu bienestar'
        />
      </p>
      <motion.div
        className='h-[8px] w-full mt-[20px] sm:mt-[34px] transition-all delay-150'
        style={{ background: props.color }}>
      </motion.div>
    </motion.div>
  )
}

export default CardBenefitsHome