import {FormattedMessage } from 'react-intl'
import React from 'react'

const BtnMagentaIcon = () => {
  return (
    <button className='border-solid border-[#FF0062] bg-[#FF0062] font-[Poppins] text-white border-2  font-bold 
    w-[315px] h-[44px] text-[15px] rounded-[10px] grid grid-cols-[1fr_35px] items-center justify-end
    sm:w-[315px] sm:h-[50px] sm:rounded-[10px] sm:grid-cols-[1fr_35px]
    hover:translate-y-[-3px] transition-all hover:shadow-md'>
        <div className=' text-center pl-[5%]'>
            <FormattedMessage
              id='PP.btn.magenta'
              defaultMessage='Quiero un nombre a rápido'/>
        </div>
        <img className='w-[20px] h-[12px] sm:w-[22px] sm:h-[14px]' src='./icons/arrow-right-white.svg' alt='icon'/>
    </button>
  )
}

export default BtnMagentaIcon