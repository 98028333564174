import React from 'react'
import { Link } from 'react-router-dom'

export const Error = () => {
  return (
    <div 
      className='w-full h-[calc(100vh-68px)] py-[50px] flex items-center justify-center'>
      <section 
        className='text-center font-[Mulish]'>
        <h1 
          className=' text-[#141414] font-[900] text-[40px] sm:text-[50px]'>
          Lo sentimos :C
        </h1>
        <img 
          alt='mainImg' src='./img/error.jpg'
          className='mx-auto mt-[14px] w-[280px]'/>
        <p 
          className='font-[400] text-[18px] sm:text-[25px] my-[26px] px-[5%] max-w-[756px]'>
          Tu pago no se pudo realizar, esto puede ser un error de tu tarjeta o de nuestro sistema. Inténtalo de nuevo más tarde.
        </p>
        <Link to='../Payment'>
          <button 
            className='bg-[#F43D3D] border-2 border-solid border-[#F43D3D] rounded-[10px]
            w-[360px] h-[64px] '>
            <span 
              className='font-[Poppins] text-[22px] font-bold text-white 
              flex items-center justify-center'>
              Vuelva a intentar
              <img 
                src='./icons/arrow-right-white.svg'
                className='ml-[30px]'/>
            </span>
          </button>
        </Link>
        
      </section>
    </div>
  )
}

