import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { apiEmailURL } from '../global/Api'

export const Congratulations = () => {
  const name = localStorage.getItem('nombre')
  const email = localStorage.getItem('email')
  const data = localStorage.getItem('data')
  const service = localStorage.getItem('Servicio')

  const dataEmail = {
    name: name,
    email: email,
    service,
    data
  }

  const requestEmail = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dataEmail)
  }

  useEffect(() => {
    fetch(apiEmailURL, requestEmail)
      .then(res => res.json())
      .catch(console.log("Error"))
  }, [])

  return (
    <div
      className='w-full min-h-[calc(100vh-68px)] flex items-center justify-center'>
      <section
        className='text-center font-[Mulish] w-[312px] sm:w-auto py-[36px] md:py-[72px]'>
        <h1
          className=' text-[#141414] font-[900] text-[36px] sm:text-[50px]'>
          ¡Felicitaciones!
        </h1>
        <img
          alt='mainImg' src='./img/congratulations.jpg'
          className='mx-auto mt-[14px] w-[280px]' />
        <p
          className='font-[400] text-[24px] sm:text-[30px] my-[26px]'>
          ¡Tu pago ha sido realizado satisfactoriamente!
        </p>

        <Link to='/'
          className='scale-75 sm:scale-100 flex justify-center'>
          <button
            className='bg-[#3BE274] border-2 border-solid border-[#3BE274] rounded-[10px]
            w-[360px] h-[64px]'>
            <span
              className='font-[Poppins] text-[24px] font-bold text-white'>
              Continuar
            </span>
          </button>
        </Link>

        <p
          className='font-[400] text-[12px] sm:text-[16px] my-[16px] sm:my-[32px]'>
          En caso no le llegue el correo, por favor{" "}
          <a
            className='my-[18px] underline text-blue-500'
            href='https://api.whatsapp.com/send?phone=+51933509472&text=%C2%A1Hola,%20quiero%20un%20proyecto%20de%20Naming%20a%20medida%20para%20mi%20nueva%20empresa!'
            target="_blank">
            contáctese
          </a>
          {" "}con nosotros.
        </p>

      </section>
    </div>
  )
}

