import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import BtnBorderBlack from '../components/buttons/BtnBorderBlack'
import BtnMagentaIcon from '../components/buttons/BtnMagentaIcon'
import CardBenefitsHome from '../components/cards/CardBenefitsHome'
import { motion } from "framer-motion"
import Tabs from '../components/Tabs.jsx'
import '../helpStyles/helpers.css'
import BtnCollaps from '../components/collaps/BtnCollaps'
import { useMediaQuery } from 'react-responsive'
import Carousel from '../components/carousel/Carousel'

const Home = () => {

  const md = useMediaQuery({ query: '(min-width: 780px)' })


  return (
    <motion.div
      transition={{ duration: 1.5 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>

      <div
        className='w-full mx-auto'>
        <section
          className='Hero pb-[20px] max-w-[1440px] mx-auto pt-0
          sm:my-[70px] md:h-auto xl:h-[450px]'>
          <div
            className='grid justify-center mx-[8%] mt-[40px] 
            md:items-center md:mx-[11.8%] xl:grid-cols-[490px_1fr]'>
            <div
              className='flex flex-col xl:items-start'>
              <p
                className='font-[Poppins] font-[800]  text-center
                text-[28px] w-[308px] mx-auto
                sm:text-[36px] sm:leading-[48px] sm:w-[394px]
                md:static md:w-[480px] md:text-[45px] md:leading-[58px]
                xl:!text-left md:mt-0 xl:text-[36px] xl:leading-[44px] xl:mx-0 '>
                <span className='text-magentaN'>
                  Naming
                </span>
                <span className='text-blackN'>
                  <FormattedMessage
                    id='PP.hero.title1'
                    defaultMessage='profesional a medida y'
                  />
                </span>
                <span className='text-magentaN'>
                  <FormattedMessage
                    id='PP.hero.title2'
                    defaultMessage='rápidos'
                  />
                </span>
              </p>
              <p className=' mt-[18px] mx-auto font-[Mulish] w-[328px] font-[400] leading-[18px] text-[#2B2326] text-center
                      text-[14px] md:w-auto transition-all
                      md:text-[22px] md:mt-[15px] sm:max-w-[510px] md:leading-[30px]
                      xl:text-[18px] xl:mt-[15px] xl:mx-0  xl:!text-left xl:leading-[25px] '>
                <FormattedMessage
                  id='PP.hero.text1'
                  defaultMessage='Somos el espacio digital que te ayudará a crear el nombre ideal para tus emprendimientos, marcas, hijas, hijos, eventos, mascotas, lugares, etc...' />
                <span className='block md:my-[15px] mt-[10px] mb-[20px]'>
                  <FormattedMessage
                    id='PP.hero.text2'
                    defaultMessage=' En menos de lo que canta un Gallo' />
                </span>
              </p>

              <div className='xl:hidden'>
                <img className=' w-[300px] mx-auto ' src='./img/home/hero.png' alt='img' />
              </div>

              <div
                className='w-auto ml-0 font-[800] text-[18px] mt-[40px] leading-[27px] 
                text-[#2B2326] !mx-auto text-center z-[-1]
                 sm:leading-[33px] sm:w-[450px] 
                 md:text-[22px] typing-demo
                 xl:flex xl:text-left xl:mt-0 xl:translate-x-[-40px]'>
                ¡Nombra o Renombra todo sin límites!<span>&#160;</span>
              </div>
              <div className='flex flex-col items-center xl:items-start'>
                <a
                  className='my-[18px]'
                  href='https://api.whatsapp.com/send?phone=+51933509472&text=%C2%A1Hola,%20quiero%20un%20proyecto%20de%20Naming%20a%20medida%20para%20mi%20nueva%20empresa!'
                  target="_blank">
                  <BtnBorderBlack />
                </a>
                <Link to='nombre-rapido'>
                  <BtnMagentaIcon />
                </Link>
              </div>
            </div>
            <div className='invisible mt-[-200vh] flex justify-end font-extrabold mb-[55px] text-[50px]
                  xl:!visible  xl:mt-0 '>
              <img className='xl:max-w-[598px]' src='./img/home/hero.png' alt='img' />
            </div>
          </div>
        </section>

        <section className='Description mb-[18px]
          sm:mb-[50px] lg:mb-[30px] xl:h-[450px] pt-0 '>
          <div className='max-w-[1110px] grid grid-cols-1  justify-end  items-center  h-full mx-auto pt-[20px]
            sm:pt-0 md:mx-[12.3%] md:gap-[24px]
            xl:grid-cols-[394px_647px] xl:gap-[69px] xl:max-w-[1110px] xl:mx-auto '>
            <img className='w-[258.38px] mx-auto sm:w-[354px] md:w-[400px]'
              alt='imagen 1' src='./img/home/description.png' />
            <div className='border-1 border-solid border-[#F1F1F1] w-[343px] shadow-[5px_5px_6px_rgba(184,184,184,0.25)] px-[30px] py-[21px] duration-300
                md:w-[647px] md:h-[273px] mx-auto hover:shadow-[10px_10px_11px_rgba(184,184,184,0.25)] hover:scale-[1.1] transition-all ease-in'
              data-aos="fade-left" data-aos-easing="ease-in-out"
              data-aos-duration="500">
              <h3 className='font-nunito font-bold text-[#FF0062] text-[18px]
                  md:text-center md:text-[24px] md:mb-[16px]
                  lg:text-center xl:text-left'>
                ¡Un naming brillante lo dice todo!
              </h3>
              <p className='font-[Mulish] font-[400] text-[#2B2326]
                  text-[15px] w-[307px] tracking-[0.3px]
                  md:text-[18px] md:w-[593px] md:leading-[25px]'>
                Un producto o servicio con un
                <span className='font-bold'> buen nombre </span>
                no necesita mayor descripción, un evento o actividad con un nombre corto y entendible tendrá más posibilidades de triunfar
                y una persona con un nombre bien diseñado (elegido) será más feliz!
              </p><br />

              <p className='font-[Mulish] font-[400]  text-[#2B2326]
                  text-[15px] w-[307px] tracking-[0.3px]
                  md:text-[18px] md:w-[593px] md:leading-[25px]'>
                Un buen nombre de ser fácil de
                <span className='font-bold'> pronunciar </span>
                , debe convertirse en
                <span className='font-bold'> verbo </span>
                y fácil de recordar
                <span className='font-bold'>(memorable)</span>.
              </p>
            </div>
          </div>
        </section>

        <section className='Benefits bg-blackN grid items-center justify-center'>
          <div className=' pb-[19px]  md:pb-[64px] transition-all duration-500'>
            <p className='text-center text-white font-[Mulish] font-extrabold text-[28px] leading-[38px]
                  md:text-[52px] transition-all duration-500 '>
              Grandes beneficios
            </p>

            <p className='text-center text-white font-[Mulish] font-[400] text-[16px] leading-[22px] w-[343px] 
                  mx-auto mt-[18px] sm:w-[450px] transition-all duration-500
                  md:w-[619px] md:mt-[49px] md:text-[16px] '>
              Un nombre bien diseñado puede lograr los objetivos planificados, por ello nuestra metodología prioriza las siguientes características
            </p>

            <div className='flex flex-col gap-[25px] items-center mt-[21px] md:mt-[63px]
                  md:grid md:grid-cols-2 h-full 
                  xl:grid-cols-3 xl:mt-[77px] md:gap-[30px] xl:gap-[53px] 
                  xl:w-[1150px]'>
              <CardBenefitsHome title="PP.function.card1title1" text="PP.function.card1text" color="#FF0062" img="./img/home/cardMemorable.png" />
              <CardBenefitsHome title="PP.function.card2title1" text="PP.function.card2text" color="#15EBEB" img="./img/home/cardVerbalizable.png" />
              <CardBenefitsHome title="PP.function.card3title1" text="PP.function.card3text" color="#FFD600" img="./img/home/cardPronunciable.png" />
            </div>
          </div>
        </section>

        <section className='font-[Mulish] flex flex-col pt-[32px] pb-[22px] sm:pt-[77px] sm:pb-[52px]' style={{ transition: "padding 0.5s ease-out" }}>
          <h2 className='font-extrabold text-blackN leading-[38px] text-center text-[28px]  sm:text-[52px]'>
            Algunos <span className='text-magentaN'>proyectos</span>
          </h2>
          <p className='font-[400]  text-center text-[16px] leading-[22px] max-w-[630px] 
            mt-[24px] mb-[32px] mx-[10%]  sm:mt-[40px] sm:mb-[55px] sm:mx-auto '>
            Cada proceso de diseño nos permite abrazar y co-crear la identidad de cada producto o servicio. Diseño tu nombre con nosotros
          </p>
          <p className='font-[Poppins] font-[600] ml-[8%] text-[16px] mb-[25px] text-left! 
            sm:hidden'>
            Caterías de proyectos
          </p>
          <Tabs />
        </section>

        <section className='bg-blackN pt-[32px] pb-[48px] md:py-[91px] px-[5%]'>
          <p className='text-center text-white font-[Mulish] font-extrabold text-[28px] leading-[38px]
            md:text-[52px] transition-all duration-500  '>
            Testimonios
          </p>
          <p className='text-center text-white font-[Mulish] font-[400] text-[16px] leading-[22px] w-[343px] 
            mx-auto mt-[18px] sm:w-[450px] transition-all duration-500
            md:w-[619px] md:mt-[64px] md:text-[20px] '>
            Nuestros clientes opinan sobre nuestro trabajo
          </p>

          <div className='max-w-[960px] mx-auto'>
            <Carousel />
          </div>
        </section>

        <section
          id='Preguntas frecuentes'
          className='bg-[#F1F4F6] text-center grid 
          gap-[32px] px-[5%]
          sm:gap-[36px] 
          md:gap-[68px]'>
          <h1 className="text-blackN">
            Preguntas frecuentes
          </h1>
          <div
            className='grid w-full mx-auto grid-cols-1 gap-[28px] items-center
          md:grid-cols-[250px_1fr] 
          lg:grid-cols-[300px_1fr] lg:gap-[54px] xl:w-[1110px]'>
            {
              md &&
              < img
                className='h-[250px] w-[250px] lg:h-[300px] lg:w-[300px]'
                alt='img'
                src='./img/home/preguntasFrecuentes.png'
              />
            }
            <div
              className='grid gap-[2px]'>
              <BtnCollaps
                id="1"
                roundedTop={true}
                roundedBot={false}
                state={true}
                question="¿Qué es Naming Design?"
                answer="Somos una plataforma de diseño de nombres profesionales para diferentes 
            tipos de usos y necesidades desde marcas, emprendimientos, nombres de personas, 
            eventos, espacios, mascotas o cualquier otro fin."
              />
              <BtnCollaps
                id="2"
                roundedTop={false}
                roundedBot={false}
                state={false}
                question="¿Cuales son los beneficios de Naming?"
                answer="Un nombre relevante y fácil de recordar para tu marca y para el mejor uso que desees"
              />
              <BtnCollaps
                id="3"
                roundedTop={false}
                roundedBot={false}
                state={false}
                question="¿Como me registro?"
                answer="No necesitas registrarte, solo realizas tu pedido, pagas y listo. 
            Te recomendamos escribir al whatsApp si tienes más consultas."
              />
              <BtnCollaps
                id="4"
                roundedTop={false}
                roundedBot={false}
                state={false}
                question="Una vez realizado el pago, ¿Cuánto se demora en crearme el Naming?"
                answer="La respuesta inicial será en 10-15 minutos posterior a realizar el pago,
            también puedes enviarnos por whatsApp o mail una captura de tu pago. Puedes elegir 
            el plan de pago inmediato, en menos de 12 horas tendremos tus propuestas terminadas, 
            el segundo es de 48 horas y el tercero de 4 o 5 días, para ello te invitamos a ver el tarifario."
              />
              <BtnCollaps
                id="5"
                roundedTop={false}
                roundedBot={true}
                state={false}
                question="¿Cuál es la diferencia entre nombre rápido y a medida?"
                answer="Nombre rápido es un proceso por el cual nos ayudamos de técnicas de investigación, 
            innovación y diseño de nombres para entregarte 3 propuestas coherentes y viables para tu necesidad. 
            El nombre a medida es una consultoría especializada que toma más tiempo, es más costosa 
            y se trabaja de la mano del cliente, si quieres conocer más, escríbenos."
              />
            </div>
          </div>
        </section>
      </div >

    </motion.div >

  )
}

export default Home