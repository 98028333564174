import React, { useState } from 'react'
import BtnBlackContinue from '../../components/buttons/BtnBlackContinue'
import { Link } from 'react-router-dom'
import { saveData } from '../../functions/saveData'

export const FormFlujo4RB = (props) => {

  const [option, setOption] = useState(props.LastValueSave ? props.LastValueSave.value : props.option[0])

  const handleChangeEvent = (e) => {
    setOption(e.target.value)
  }

  return (
    <div className='max-w-[800px] mx-auto flex-none h-full px-[5%] xl:px-0'>
      <h1
        className='font-nunito font-[600] text-black text-center mx-auto 
         text-[18px] leading-[25px] w-[226px]
         sm:text-[36px] sm:leading-[44px] sm:w-[511px]
         lg:text-[36px] lg:leading-[44px] lg:w-[511px]'>
        {props.title}
      </h1>
      <div
        className='pt-[24px] relative grid flex-row-reverse
         gap-[11px] justify-center sm:pt-[44px]
         lg:grid-cols-[350px_333px] lg:gap-[121px] lg:justify-start'>
        <img
          alt="Hola"
          className=' mx-auto h-[124px] sm:h-[360px] lg:order-2 lg:mx-0'
          src={props.img}
        />
        <div>
          <h3
            className='font-[Mulish] font-bold text-blackN 
             text-[17px] leading-[25px] text-center
             sm:text-[20px] sm:leading-[28px]
             lg:text-[24px] lg:leading-[30px] lg:!text-left'>
            {props.subtitle}
          </h3>
          <form className='pt-[13px] pb-[15px]'>
            <ul>
              <li className='h-[40px]'>
                <input
                  type="radio"
                  id="option1"
                  onChange={handleChangeEvent}
                  checked={option === props.option[0] ? 'checked' : ''}
                  name="option"
                  value={props.option[0]}
                  style={stylesRB}
                />
                <label
                  className='text-[14px] sm:text-[16px]'
                  htmlFor="option1"
                  style={styleLabel}>
                  {props.option[0]}
                </label>
              </li>

              <li className='h-[40px] flex'>
                <input
                  type="radio"
                  id="option2"
                  onChange={handleChangeEvent}
                  checked={option === props.option[1] ? 'checked' : ''}
                  name="option"
                  value={props.option[1]}
                  style={stylesRB}
                />
                <label
                  className='text-[14px] sm:text-[16px]'
                  htmlFor="option2"
                  style={styleLabel}>
                  {props.option[1]}
                </label>
              </li>
            </ul>
          </form>
          <Link
            className='bottom-0 mx-auto relative flex justify-center left-0 lg:absolute'
            to={props.nextLinkNavigation}
            onClick={() => saveData(option, props.subtitle, "Form2", props.LastValueSave)}>
            <BtnBlackContinue active="true" />
          </Link>
        </div>
      </div>
    </div>
  )
}


const stylesRB = {
  color: "#FF0062",
  height: "26.67px",
  width: "26.67px",
  ':active': {
    border: '1px solid black'
  },
}

const styleLabel = {
  fontFamily: "Mulish",
  fontWeight: 400,
  color: "#263238",
  marginLeft: "10px"
}

