import React from 'react'

const CardPlanes = (props) => {
    return (
        <div
            style={{ borderColor: props.titleColor }}
            className='text-start bg-[#F9F9F9] w-[285px] py-[25px] px-[18px]
            border-b-[5px] border-black scale-90 sm:scale-[0.85] md:scale-100'>
            <h4
                style={{ color: props.titleColor }}
                className="text-[22px] leading-[25px] font-[Poppins] font-[900]">
                {props.title}
            </h4>
            <p
                className='text-[20px] mt-[14px] font-[900]'>
                S/ {props.price}.00
            </p>
            <div className='text-[15px] pl-2 font-[400]'>
                {
                    props.items.map((item) => (
                        <li>{item}</li>
                    ))
                }
            </div>
        </div>
    )
}

export default CardPlanes