import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from './components/Footer'
import FooterSecondary from './components/FooterSecondary'
import NavbarMain from './components/NavbarMain'
import NavbarSecondary from './components/NavbarSecondary'
import { ProviderOptionNavContext, ProviderPreviusPagContext } from './context/helperNavContext'

const Main = () => {

  const location = useLocation()
  const urlActual = location.pathname

  function NavSecondary() {
    if (urlActual === '/DataForm') {
      return <NavbarSecondary style='black' />
    } else {
      return <NavbarSecondary style='white' />
    }
  }
  return (
    <div className='w-full shadow-md'>
      {
        urlActual === '/' || urlActual === '/Payment' || urlActual === '/Congratulations' ||
          urlActual === '/Error' || urlActual === '/nombre-rapido' || '/nombre-rapido/:typeName' || urlActual === '/nombres-para-personas-y-mascotas'
          || urlActual === '/nombres-para-marcas-y-negocios'
          ?
          <NavbarMain /> : NavSecondary()
      }
      <ProviderOptionNavContext>
        <ProviderPreviusPagContext>
          <Outlet />
        </ProviderPreviusPagContext>
      </ProviderOptionNavContext>
      {urlActual === '/' || urlActual === '/nombres-para-personas-y-mascotas' || urlActual === '/nombres-para-marcas-y-negocios' ?
        <Footer />
        :
        <FooterSecondary style='black' />
      }
    </div>
  )
}

export default Main