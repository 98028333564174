import React from 'react'

const BtnBorderBlackOmit = () => {
  return (
    <button className='border-solid border-blackN bg-white font-[Poppins] text-blackN border-2  font-bold 
    w-[107px] h-[43px]  rounded-[10px]  text-[18px] leading-[27px]
    sm:w-[200px] sm:h-[50px] sm:rounded-[10px] sm:text-[18px] 
    hover:text-white hover:!bg-blackN transition-all duration-500'>
        <div className=''>
            Omitir
        </div>
    </button>
  )
}

export default BtnBorderBlackOmit