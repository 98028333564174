import React, { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import BtnBlackIconNext from '../components/buttons/BtnBlackIconNext'
import BtnBorderBlackOmit from '../components/buttons/BtnBorderBlackOmit'
import { motion } from "framer-motion"


const Onboarding = () => {
  const carousel = useRef(null)
  
  function izquierda() {
    carousel.current.scrollLeft -= carousel.current.offsetWidth
  }

  function derecha() {
    carousel.current.scrollLeft += carousel.current.offsetWidth
  }

  return (
    <motion.div
      transition={{ duration: 1.5 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className='w-full min-h-[100vh] pt-[10%] sm:pt-[4%] pb-[5%]'>
      <div className='carousel w-[320px] sm:w-[650px] md:w-[750px] lg:w-[838px] flex mx-auto
      overflow-hidden overflow-y-hidden scroll-smooth transition-all delay-500' ref={carousel}>

        <div className=' flex-none w-[320px] sm:w-[650px] md:w-[750px] lg:w-[838px]'>
          <h1 className="flex justify-center font-[Mulish] font-extrabold text-black
            leading-[25px] text-[24px]
            sm:leading-[38px] sm:text-[52px] transition-all" >
            Responde
          </h1>
          <span className='block text-center font-[Mulish] font-[400] text-blackN
            leading-[17.57px] text-[14px] mt-[16px]
            sm:leading-[25px] sm:text-[18px] sm:mt-[40px]'>
            1/3
          </span>
          <div className='grid mt-[22px] text-center 
            sm:mt-[54px] 
            md:mt-[34px]
            lg:grid lg:grid-cols-[315px_322px] lg:gap-[76px] lg:mt-[48px] lg:text-left'>
            <div className='order-2 lg:order-1 w-full'>
              <h2 className='font-nunito font-extrabold text-center lg:!text-left
                leading-[25px] text-[17px] w-[270px] mx-auto mt-[44px]
                sm:leading-[32.74px] sm:text-[24px] sm:w-[438px]  sm:mt-0
                md:mt-[30px] lg:mx-0 lg:mt-0 transition-all'>
                Completa las preguntas para ayudarte a crear el nombre que necesites
              </h2>
              <p className='font-[Mulish] font-[400]
                text-[18px] leading-[25px] mt-[40px] lg:text-left
                sm:text-[18px] sm:leading-[25px] sm:mt-[34px] transition-all'>
                Registra tus respuestas, encontrarás ejemplos de ayuda en cada cuadro.
              </p>
              <div className='flex pl-[2px]
                mt-[72px] gap-[33px] justify-center
                sm:gap-[40px]  md:mt-[52px]
                lg:mt-[180px] lg:justify-start transition-all'>
                <Link to={"/creacion-de-nombre-professional/01"}>
                  <BtnBorderBlackOmit />
                </Link>
                <button onClick={() => derecha()}>
                  <BtnBlackIconNext />
                </button>
              </div>
            </div>
            <img
              className='order-1 mx-auto w-[230px] 
              sm:h-auto sm:w-[322px] lg:order-2 bg-white'
              alt='img'
              src='/img/onboarding/respond.png'
            />
          </div>
        </div>

        <div className=' flex-none w-[320px] sm:w-[650px] md:w-[750px] lg:w-[838px] '>
          <h1 className="relative flex justify-center font-[Mulish] font-extrabold text-black
            leading-[25px] text-[24px]
            sm:leading-[38px] sm:text-[52px] transition-all" >
            <button onClick={() => izquierda()}>
              <img className='rotate-180 absolute left-0 top-1/4 '
                alt='arrow' src='/icons/arrow-right-black.svg' />
            </button>
            Paga
          </h1>
          <span className='block text-center font-[Mulish] font-[400] text-blackN
            leading-[17.57px] text-[14px] mt-[16px]
            sm:leading-[25px] sm:text-[18px] sm:mt-[40px]'>
            2/3
          </span>
          <div className='grid mt-[22px] text-center 
            sm:mt-[54px]
            md:mt-[34px]
            lg:grid lg:grid-cols-[315px_322px] lg:gap-[76px] lg:mt-[48px] lg:text-left'>
            <div className='order-2 lg:order-1'>
              <h2 className='font-nunito font-extrabold text-center lg:!text-left
                leading-[25px] text-[17px] w-[270px] mx-auto mt-[44px]
                sm:leading-[32.74px] sm:text-[24px] sm:w-[438px]  sm:mt-0
                md:mt-[30px] lg:mx-0 lg:mt-0  transition-all'>
                Abona un importe mayor a S/ 20 para poder compartirte el nombre
              </h2>
              <p className='font-[Mulish] font-[400]
                text-[18px] leading-[25px] mt-[40px] lg:text-left
                sm:text-[18px] sm:leading-[25px] sm:mt-[34px] transition-all'>
                Realiza una colaboración para seguir contribuyendo a la mejora del servicio.
              </p>
              <div className='flex 
                mt-[72px] gap-[33px] justify-center
                sm:gap-[40px]  md:mt-[52px]
                lg:mt-[180px] lg:justify-start transition-all'>
                <Link to={"/creacion-de-nombre-professional/01"}>
                  <BtnBorderBlackOmit />
                </Link>
                <button onClick={() => derecha()}>
                  <BtnBlackIconNext />
                </button>
              </div>
            </div>
            <img className='order-1 mx-auto h-[145px] sm:h-auto lg:h-auto lg:order-2 sm:w-[322px]' alt='img' src='/img/onboarding/pay.png' />
          </div>
        </div>
        <div className=' flex-none w-[320px] sm:w-[650px] md:w-[750px] lg:w-[838px] '>
          <h1 className="relative flex justify-center font-[Mulish] font-extrabold text-black
            leading-[25px] text-[24px]
            sm:leading-[38px] sm:text-[52px] transition-all" >
            <button onClick={() => izquierda()}>
              <img className='rotate-180 absolute left-0 top-1/4 '
                alt='arrow' src='/icons/arrow-right-black.svg' />
            </button>
            Recibe
          </h1>
          <span className='block text-center font-[Mulish] font-[400] text-blackN
            leading-[17.57px] text-[14px] mt-[16px]
            sm:leading-[25px] sm:text-[18px] sm:mt-[40px]'>
            3/3
          </span>
          <div className='grid mt-[22px] text-center 
            sm:mt-[54px]
            md:mt-[34px]
            lg:grid lg:grid-cols-[315px_322px] lg:gap-[76px] lg:mt-[48px] lg:text-left'>
            <div className='order-2 lg:order-1'>
              <h2 className='font-nunito font-extrabold 
                leading-[25px] text-[17px] w-[270px] mx-auto mt-[44px]
                sm:leading-[32.74px] sm:text-[24px] sm:w-[438px]  sm:mt-0
                md:mt-[30px] lg:mx-0  lg:mt-0  transition-all text-center lg:!text-left'>
                Recibe el nombre que hemos creado a través de tu correo electrónico
              </h2>
              <p className='font-[Mulish] font-[400]
                text-[18px] leading-[25px] mt-[40px] lg:text-left
                sm:text-[18px] sm:leading-[25px] sm:mt-[34px] transition-all'>
                Revisa tu bandeja, te compartiremos el nombre por ese medio.
              </p>
              <div className='flex 
                mt-[72px] gap-[33px] justify-center
                sm:gap-[40px]  md:mt-[52px]
                lg:mt-[180px] lg:justify-start transition-all'>
                <Link to={"/creacion-de-nombre-professional/01"}>
                  <BtnBorderBlackOmit />
                </Link>
                <Link to={"/creacion-de-nombre-professional/01"}>
                  <BtnBlackIconNext />
                </Link>
              </div>
            </div>
            <img className='order-1 mx-auto h-[145px] sm:h-auto sm:w-[322px] lg:order-2' alt='img' src='/img/onboarding/receives.png' />
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Onboarding