import React from 'react'
import { FormTypeInput } from '../../components/form/FormTypeInput'
import { FormFlujo3RB } from '../../components/form/FormFlujo3RB'
import ArrowBackPage from '../../components/navigation/ArrowBackPage'
import { checkPreviusValue } from '../../functions/checkPreviusValue'
import { motion } from "framer-motion"

const Form3 = () => {
  const db_data = require("../../db/form/DataForm.json")
  const data1 = db_data.flow1.page3
  const data12 = db_data.flow12.page3
  const data13 = db_data.flow13.page3
  const data2 = db_data.flow2.page3
  const data3 = db_data.flow3.page3
  const data4 = db_data.flow4.page3
  const data42 = db_data.flow42.page3

  const { LastValueSave, lastItem } = checkPreviusValue(3)

  return (
    <motion.div
      transition={{ duration: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='max-w-[1016px] mx-auto h-full pb-[5%] relative'>
      <ArrowBackPage beforeLink="/creacion-de-nombre-professional/02" />
      {(() => {
        switch (lastItem.subtitle) {
          case "¿Este nombre sera para un producto , servicio o negocio?":

            if (lastItem.value === "Producto") {
              return <FormTypeInput {...data1} LastValueSave={LastValueSave} />
            } else if (lastItem.value === "Servicio") {
              return <FormTypeInput {...data12} LastValueSave={LastValueSave} />
            } else {
              return <FormTypeInput {...data13} LastValueSave={LastValueSave} />
            }

          case "Cuéntanos, ¿Cómo es tu mascota?":
            return <FormTypeInput {...data2} LastValueSave={LastValueSave} />

          case "Cuéntanos, ¿Cuándo es el nacimiento de tu hij@?":
            return <FormFlujo3RB {...data3} LastValueSave={LastValueSave} />

          case "¿Es un evento o celebración?":

            if (lastItem.value === "Evento (Congreso, concierto, fiesta...") {
              return <FormTypeInput {...data4} LastValueSave={LastValueSave} />
            } else if (lastItem.value === "Celebración (Cumpleaños, primera comunión...") {
              return <FormTypeInput {...data42} LastValueSave={LastValueSave} />
            }

          default:
            return null
        }
      })()
      }
    </motion.div>
  )
}

export default Form3