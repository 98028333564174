import React from 'react'
import { Link } from 'react-router-dom'

const ArrowBackPage = (props) => {
   return (
      <Link
         to={props.beforeLink}
         className="cursor-pointer z-50">
         <img
            className='rotate-180 scale-100 absolute 
             left-[5%] top-[8px]
             sm:left-[5%] sm:top-[16px] sm:scale-125
             md:left-[12%] md:top-[20px]
             lg:left-[8%] 
             xl:left-[0%] xl:top-[20px] z-10'
            src="/icons/arrow-right-black.svg"
            alt="arrow-back"
         />
      </Link>
   )
}

export default ArrowBackPage