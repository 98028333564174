import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import BtnBorderBlackIcon from '../../components/buttons/BtnBorderBlackIcon'
import BtnBorderMagenta from '../../components/buttons/BtnBorderMagenta'
import BtnBorderWhiteIcon from '../../components/buttons/BtnBorderWhiteIcon'
import CardPlanes from '../../components/cards/CardPlanes'
import CardsBorderTopColor from '../../components/cards/CardsBorderTopColor'
import HeroChange from '../../components/imgHidden/HeroChange'
import { motion } from "framer-motion"

const NamePeople = () => {

  const lg = useMediaQuery({ query: '(min-width: 1024px)' })

  return (
    <motion.div
      transition={{ duration: 1.5 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>

      <main id='hero' className=''>
        <div
          className=' flex flex-col justify-center w-full 
          gap-[18px]  sm:gap-[28px]
          lg:text-left lg:w-[500px]'>
          <h2 className='text-center lg:!text-left'>
            <span className='text-magentaN'>Nombres rápidos</span> para personas y mascotas
          </h2>
          <p
            className='text-[16px] sm:text-[20px] lg:text-[18px] text-center lg:!text-start'>
            Somos una plataforma de diseño de nombres profesionales para diferentes tipos de usos y necesidades
            desde marcas, emprendimientos, nombres de personas, eventos, espacios, mascotas o cualquier otro fin.
          </p>
          {!lg && <HeroChange img="./img/buss/hero-page-people.png" />}
          <Link
            to="/nombre-rapido"
            className='flex justify-center lg:justify-start'>
            <BtnBorderBlackIcon text="Quiero un nombre rápido" />
          </Link>
        </div>
        {lg && <HeroChange img="./img/buss/hero-page-people.png" />}
      </main>
      <section
        id='¿Cómo funciona?'
        className='bg-blackN text-center grid 
        gap-[32px] px-[5%]
        sm:gap-[36px] 
        md:gap-[48px]'>
        <h1 className="text-white">
          ¿Cómo funciona?
        </h1>
        <p className="text-white text-[15px] sm:text-[18px] lg:text-[16px]">
          Ingresa a{" "}
          <Link
            to="/nombre-rapido"
            className='font-bold border-b-[1.5px]'>
            nuestra plataforma
          </Link>
          {" "}y sigue los siguientes pasos:
        </p>
        <div className=" grid  justify-center 
          mx-auto gap-[8px] 
          px-[5%] sm:gap-[48px]
          md:grid-cols-2 md:gap-[24px]
          lg:grid-cols-3 lg:gap-[11px] lg:px-[3%]
          xl:px-0 xl:max-w-[1117px] ">
          <CardsBorderTopColor
            title="Responde"
            text="Completa las preguntas para ayudarte a crear el nombre que necesites"
            color="#FF0062"
            img="/img/buss/onboarding-response.png"
          />
          <CardsBorderTopColor
            title="Paga"
            text="Realiza una colaboración para seguir contribuyendo a la mejora del servicio."
            color="#15EBEB"
            img="/img/buss/onboarding-pay.png"
          />
          <CardsBorderTopColor
            title="Recibe"
            text="Compartiremos las propuestas de nombre que hemos creado a través de tu email."
            color="#FFD600"
            img="/img/buss/onboarding-receives.png"
          />
        </div>
        <Link
          to="/nombre-rapido"
          className="flex justify-center">
          <BtnBorderMagenta text="Haz tu pedido aquí" />
        </Link>
      </section>
      <section
        id='Conoce nuestros planes'
        className='sectionsTypeHero'>
        <div
          className=' flex flex-col justify-center text-center w-full 
          gap-[24px]  sm:gap-[28px]
          lg:text-left lg:w-[594px]'>
          <h2>
            Conoce nuestros planes
          </h2>

          <div
            className='grid justify-center gap-[2px] 
            sm:grid-cols-2 sm:gap-[24px]'>
            <CardPlanes
              title="Plan Regular"
              titleColor="#15EBEB"
              price="50"
              items={["Pagó único", "Entrega entre 2 a 3 días", "Metodología innovadora", "4 opciones viables para ti"]}
            />
            <CardPlanes
              title="Plan Veloz"
              titleColor="#FF0062"
              price="70"
              items={["Pagó único", "Entrega máxima en 24 horas", "Metodología innovadora", "4 opciones viables para ti"]}
            />
          </div>
          <p
            className='text-[16px] lg:text-[18px] text-center sm:text-[20px] lg:!text-start'>
            Realiza tu pedido aquí de manera rápida y sencilla.
          </p>
          <Link
            to="/nombre-rapido"
            className='flex justify-center lg:justify-start '>
            <BtnBorderMagenta text="Haz tu pedido aquí" />
          </Link>
          {!lg && <HeroChange img="./img/buss/pay-phone.png" />}
        </div>
        {lg && <HeroChange img="./img/buss/pay-phone.png" />}
      </section>
    </motion.div>
  )
}

export default NamePeople